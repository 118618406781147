export const HOLDINGSUMMARYBYISSENT = 'holding-summaries/is-sent';
export const HOLDINGSUMMARYBYQUARTER = 'holding-summaries/by-quarter';
export interface HoldingsSummary {
  entity: Object;
  valuation_date: Date;
  generated_date: Date;
  updatedAt: Date;
  user: string;
}
export interface HoldingsSummarybyQuarter {
  fund_name: String;
  fund_class_series: String;
  name: String;
  data: databyQuarter[];
}
export interface databyQuarter {
  quarter: String;
  asset_value_usd: Number;
}
export interface FilterHoldingsSummary {
  valuation_date: Date;
  total_asset_value: Number;
}
