<mat-toolbar class="toolbar-holding-summary">
  <div class="holding-summary-toolbar-container">
    <form class="holding-summary-form" [formGroup]="formGroup">
      <div fxLayout="column" class="input">
        <mat-form-field appearance="fill">
          <input
            matInput
            [matDatepicker]="reportDate"
            formControlName="evaluation_date"
            placeholder="Valuation Date"
            (dateChange)="changeFilterValue(formGroup.value)"
          />
          <mat-datepicker-toggle matSuffix [for]="reportDate">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              matDatepickerToggleIcon
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13 2H11V1H10V2H6V1H5V2H3C2.73489 2.00033 2.48072 2.10579 2.29326 2.29326C2.10579 2.48072 2.00033 2.73488 2 3V13C2.00033 13.2651 2.10579 13.5193 2.29326 13.7067C2.48072 13.8942 2.73489 13.9997 3 14H13C13.2651 13.9997 13.5193 13.8942 13.7067 13.7067C13.8942 13.5193 13.9997 13.2651 14 13V3C13.9997 2.73488 13.8942 2.48072 13.7067 2.29326C13.5193 2.10579 13.2651 2.00033 13 2ZM3 3H5V4H6V3H10V4H11V3H13V5H3V3ZM3 6H5.5V9H3V6ZM9.5 13H6.5V10H9.5V13ZM9.5 9H6.5V6H9.5V9ZM10.5 13V10H13L13.0006 13H10.5ZM3 10H5.5V13H3V10ZM13 6H10.5V9H13V6Z"
                fill="#45494E"
              />
            </svg>
          </mat-datepicker-toggle>

          <mat-datepicker #reportDate></mat-datepicker>
        </mat-form-field>
      </div>
    </form>
  </div>
</mat-toolbar>
