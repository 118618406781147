import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { delay, filter } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @ViewChild('contact') contact: ElementRef;
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;
  breakpointCheck = false;
  isShow = false;
  email = 'client-operations@silverhorngroup.com';
  constructor(
    private observer: BreakpointObserver,
    private router: Router,
    private renderer: Renderer2,
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.isShow == true && e.target !== this.contact?.nativeElement) {
        this.isShow = false;
      }
    });
  }
  ngOnInit(): void {}

  ngAfterViewInit() {
    // Breakpoint at 1366px
    this.observer
      .observe(['(max-width: 1366px)'])
      .pipe(delay(1), untilDestroyed(this))
      .subscribe((res) => {
        if (res.matches) {
          this.sidenav.mode = 'over';
          this.sidenav.close();
          this.breakpointCheck = true;
        } else {
          this.sidenav.mode = 'side';
          this.sidenav.open();
          this.breakpointCheck = false;
        }
      });

    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((e) => e instanceof NavigationEnd),
      )
      .subscribe(() => {
        if (this.sidenav.mode === 'over') {
          this.sidenav.close();
        }
      });
  }
  showEmailSupport() {
    event.stopPropagation();
    this.isShow = !this.isShow;
  }
}
