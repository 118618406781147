import { Injectable } from '@angular/core';
import { ApiHttpService } from '@core/api/api-http.service';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  CLIENT_FUND_POSITION_LIST_URL,
  ManagedAccountHistory,
  ManagedAccountValue,
  MANAGED_ACCOUNT_HISTORY_URL,
  MANAGED_ACCOUNT_VALUE_URL,
  PositionFund,
  TransactionsByQuarter,
  TRANSACTION_BY_QUARTER_URL,
} from './dashboard.const';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private is_data_chart = new BehaviorSubject<boolean>(false);
  public isDataChart$ = this.is_data_chart.asObservable();
  constructor(private apiService: ApiHttpService) {}
  public getManagedAccountValue(): Observable<ManagedAccountValue> {
    return this.apiService.get(MANAGED_ACCOUNT_VALUE_URL);
  }
  public getTransactionByQuarter(): Observable<TransactionsByQuarter[]> {
    return this.apiService.get(TRANSACTION_BY_QUARTER_URL);
  }
  public getManagedAccountHistory(): Observable<ManagedAccountHistory[]> {
    return this.apiService.get(MANAGED_ACCOUNT_HISTORY_URL);
  }
  public getClientFundPositionList(): Observable<PositionFund[]> {
    return this.apiService.get(CLIENT_FUND_POSITION_LIST_URL);
  }
  public checkDataChart(isValue:boolean){
    this.is_data_chart.next(isValue)
  }
}
