import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TransactionsService } from '../transactions.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { delay } from 'rxjs';
@UntilDestroy()
@Component({
  selector: 'app-transactions-filter',
  templateUrl: './transactions-filter.component.html',
  styleUrls: ['./transactions-filter.component.scss'],
})
export class TransactionsFilterComponent implements OnInit {
  formGroup: UntypedFormGroup;
  touched: boolean = false;
  filterChip = {
    fund: [],
    sector: [],
    region: [],
  };
  nullForm;
  breakpointCheck = false;
  fundNameOptions = [];
  fundClassOptions = [];
  fundSeriesOptions = [];
  transactionTypeOptions = [];
  dataFundName = [];
  dataFundClass = [];
  dataFundSeries = [];
  dataFilter;
  isSelectFundClass = true;
  isSelectFundSeries = true;
  constructor(
    private _fb: UntypedFormBuilder,
    private service: TransactionsService,
    private observer: BreakpointObserver,
  ) {}
  ngAfterViewInit() {
    // Breakpoint at 1366px
    this.observer
      .observe(['(max-width: 1366px)'])
      .pipe(delay(1), untilDestroyed(this))
      .subscribe((res) => {
        if (res.matches) {
          this.breakpointCheck = true;
        } else {
          this.breakpointCheck = false;
        }
      });
  }
  ngOnInit(): void {
    this.formGroup = this._createFormGroup();
    this.nullForm = this.formGroup.getRawValue();
    this.reload();
    this.formGroup.get('fund_name').valueChanges.subscribe((data) => {
      if (data) {
        this.isSelectFundClass = false;
        this.dataFilter.map((values) => {
          if (data.toLowerCase() === values.product.name.toLowerCase()) {
            if (values.fund_class) {
              this.dataFundClass.push(values.fund_class);
              this.fundClassOptions = this.formatDataFilterOptions(
                this.dataFundClass,
                'fund_class_name',
              );
            }
          } else {
            this.fundClassOptions = this.formatDataFilterOptions(
              this.dataFundClass,
              'fund_class_name',
            );
          }
        });
        this.dataFundClass = [];
      } else {
        this.isSelectFundClass = true;
        this.fundClassOptions = this.formatDataFilterOptions(
          this.dataFundClass,
          'fund_class_name',
        );
      }
      this.formGroup.get('fund_class').setValue(null);
      this.formGroup.get('fund_class').updateValueAndValidity();
    });
    this.formGroup.get('fund_class').valueChanges.subscribe((data) => {
      if (data) {
        this.isSelectFundSeries = false;
        const fund_name = this.formGroup.get('fund_name').value;
        this.dataFilter.map((values) => {
          if (
            values.fund_class &&
            data.toLowerCase() ===
              values.fund_class.fund_class_name.toLowerCase() &&
            fund_name.toLowerCase() === values.product.name.toLowerCase()
          ) {
            if (values.fund_series) {
              this.dataFundSeries.push(
                values.fund_series ? values.fund_series : null,
              );
              this.fundSeriesOptions = this.formatDataFilterOptions(
                this.dataFundSeries,
                'fund_series_name',
              );
            } else {
              this.fundSeriesOptions = this.formatDataFilterOptions(
                this.dataFundSeries,
                'fund_series_name',
              );
            }
          }
        });
        this.dataFundSeries = [];
      } else {
        this.isSelectFundSeries = true;
        this.fundSeriesOptions = this.formatDataFilterOptions(
          this.dataFundSeries,
          'fund_series_name',
        );
      }
      this.formGroup.get('fund_series').setValue(null);
      this.formGroup.get('fund_series').updateValueAndValidity();
    });
  }
  reload() {
    this.service.dataTransaction$.subscribe((data) => {
      if (data) {
        this.dataFilter = data;
        data.map((values) => {
          this.dataFundName.push(values.product);
        });
        this.fundNameOptions = this.formatDataFilterOptions(
          this.dataFundName,
          'name',
        );

        this.transactionTypeOptions = this.formatDataFilterOptions(
          data,
          'transaction_type',
        );
      }
    });
  }

  changeFilterValue(filterValue) {
    event.preventDefault();
    this.checkTouched(filterValue);
    this.service.changeFilterValue(filterValue);
  }
  downloadExcel(e: Event) {
    e.preventDefault();
    this.service.downnloadExcel();
  }
  checkTouched(filterValue) {
    let a = Object.entries(filterValue).toString();
    let b = Object.entries(this.nullForm).toString();
    if (a === b || a === Object.entries({}).toString()) {
      this.touched = false;
    } else {
      this.touched = true;
    }
  }
  private _createFormGroup(): UntypedFormGroup {
    return this._fb.group({
      fund_name: [null],
      fund_class: [null],
      fund_series: [null],
      transaction_type: [null],
    });
  }
  selectOption(event, name): void {
    this.filterChip[name].push(event.option.value);
    this.formGroup.get(name).setValue(this.filterChip[name]);
    this.changeFilterValue(this.formGroup.value);
  }
  removeChip(index: number, name): void {
    this.filterChip[name].splice(index, 1);
    this.formGroup.get(name).setValue(this.filterChip[name]);
    this.changeFilterValue(this.formGroup.value);
  }
  onReset() {
    this.touched = false;
    this.filterChip.fund = [];
    this.filterChip.sector = [];
    this.filterChip.region = [];
    this.formGroup?.reset();
    this.service.changeFilterValue(this.formGroup.getRawValue());
  }
  formatDataFilterOptions(data, type) {
    const result = [
      ...data
        .reduce((r, o) => {
          const key = o[type];
          const item =
            r.get(key) ||
            Object.assign(
              {},
              {
                desc: o[type],
              },
            );
          return r.set(key, item);
        }, new Map())
        .values(),
    ];
    const formatData = result.sort((a, b) => a.desc.localeCompare(b.desc));
    return formatData;
  }
}
