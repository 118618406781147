import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
  AccountInfo,
  AuthenticationResult,
  BrowserUtils,
  EventMessage,
  EventType,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest,
  SsoSilentRequest,
} from '@azure/msal-browser';
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import { Location } from '@angular/common';
import { MsalAuthService } from '@core/msal/msal.auth.service';
import { environment, b2cPolicies } from '../environments/environment';
import { IdTokenClaims, PromptValue } from '@azure/msal-common';
type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string;
  tfp?: string;
};
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isIframe = false;
  isLoggedIn = false;
  isLocal = false;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private _msalAuthService: MsalAuthService,
    private msalBroadcastService: MsalBroadcastService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private location: Location,
  ) {}

  ngOnInit(): void {
    const currentPath = this.location.path();
    // Dont perform nav if in iframe or popup, other than for front-channel logout
    this.isIframe =
      BrowserUtils.isInIframe() &&
      !window.opener &&
      currentPath.indexOf('logout') < 0; // Remove this line to use Angular Universal
    this.isLoggedIn = this._msalAuthService.isLoggedIn();
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None,
        ),
        takeUntil(this._destroying$),
      )
      .subscribe(() => {
        this.isLoggedIn = this._msalAuthService.isLoggedIn();
        this._msalAuthService.checkAndSetActiveAccount();
      });
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS,
        ),
        takeUntil(this._destroying$),
      )
      .subscribe((result: EventMessage) => {
        let payload = result.payload as AuthenticationResult;
        let idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

        if (
          (typeof idtoken.acr !== 'undefined' && idtoken.acr.toUpperCase() === b2cPolicies.names.signIn) ||
          (typeof idtoken.tfp !== 'undefined' && idtoken.tfp.toUpperCase() === b2cPolicies.names.signIn)
        ) {
          this._msalAuthService.checkAndSetActiveAccount();
          this.isLoggedIn = this._msalAuthService.isLoggedIn();
          this._msalAuthService.checkAndSetActiveAccount();
        }
        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         * If you do not want this behavior and prefer your users to stay signed in instead,
         * you can replace the code below with the same pattern used for handling the return from
         * profile edit flow (see above ln. 74-92).
         */
        
        if (
          (typeof idtoken.acr !== 'undefined' && idtoken.acr.toUpperCase() === b2cPolicies.names.resetPassword) ||
          (typeof idtoken.tfp !== 'undefined' && idtoken.tfp.toUpperCase() === b2cPolicies.names.resetPassword)
        ) {
          this.logout();
        }
        return result;
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_FAILURE ||
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE,
        ),
        takeUntil(this._destroying$),
      )
      .subscribe((result: EventMessage) => {
        // Checking for the forgot password error. Learn more about B2C error codes at
        // https://learn.microsoft.com/azure/active-directory-b2c/error-codes
        if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
          let resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
            authority: b2cPolicies.authorities.resetPassword.authority,
            scopes: [],
          };
          this.login(resetPasswordFlowRequest);
        }
      });
    this.isLocal = !environment.production;
  }
  logout() {
    this._msalAuthService.logout();
  }
  
  forgotPassword = ()=> {
    let resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
      authority: b2cPolicies.authorities.resetPassword.authority,
      scopes: [],
    };
    this._msalAuthService.login(resetPasswordFlowRequest);
  }
  login = (userFlowRequest?: RedirectRequest | PopupRequest) => {
    this._msalAuthService.login(userFlowRequest);
  };

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
}
