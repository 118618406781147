import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalAuthService } from '@core/msal/msal.auth.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiHttpService {
  httpOptions;
  endpoint = environment.core.api.endpoint;
  constructor(
    private _msalAuthService: MsalAuthService,
    private http: HttpClient,
  ) {
    const currentUser = this._msalAuthService.currentActiveUser();
    this.httpOptions = {
      headers: new HttpHeaders({
        'x-active-directory-local-account-id': currentUser.localAccountId,
        'x-email': currentUser.idTokenClaims["signInNames.emailAddress"], //first email
        'x-auth-token': environment.core.api.key,
      }),
    };
  }
  public get(url: any, header?: any): Observable<any> {
    return this.http.get(
      `${this.endpoint + url}`,
      header ? header : this.httpOptions,
    );
  }

  public post(url: any, body?: any, header?: any): Observable<any> {
    return this.http.post(
      `${this.endpoint + url}`,
      body,
      header ? header : this.httpOptions,
    );
  }

  public put(url: any, body?: any, header?: any): Observable<any> {
    return this.http.put(
      `${this.endpoint + url}`,
      body,
      header ? header : this.httpOptions,
    );
  }

  public delete(url: any, body?: any, header?: any): Observable<any> {
    return this.http.delete(
      `${this.endpoint + url}`,
      header ? header : this.httpOptions,
    );
  }
}
