import { PlatformLocation } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenav } from '@angular/material/sidenav';
import { DomSanitizer } from '@angular/platform-browser';
import { MsalAuthService } from '@core/msal/msal.auth.service';
import { SIDENAV_CONTENTS } from './home-sidenav.constant';

@Component({
  selector: 'app-home-sidenav',
  templateUrl: './home-sidenav.component.html',
  styleUrls: ['./home-sidenav.component.scss'],
})
export class HomeSidenavComponent implements OnInit {
  @Input() sidenav!: MatSidenav;
  @Input() breakpointCheck!: boolean;
  constructor(
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
    private pLocation: PlatformLocation,
    private _msalAuthService: MsalAuthService,
  ) {
    matIconRegistry.addSvgIcon(
      'dashboard',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/dashboard.svg'),
    );
    matIconRegistry.addSvgIcon(
      'funds',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/fund_management.svg'),
    );
    matIconRegistry.addSvgIcon(
      'transactions',
      domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/transaction_management.svg',
      ),
    );
    matIconRegistry.addSvgIcon(
      'holding-summaries',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/reports.svg'),
    );
  }
  sidenavContents = SIDENAV_CONTENTS;
  selectedMenu: string;
  ngOnInit(): void {
    this.selectedMenu = (this.pLocation as any).location.pathname;
    if (this.selectedMenu === '/') {
      this.selectedMenu = '/dashboard';
    }
  }
  selectMenu(link) {
    this.selectedMenu = link;
  }
  shrinkSidenav(): void {
    this.sidenav.close();
  }
  logout(): void {
    this._msalAuthService.logout();
  }
}
