import { Component, Input, OnInit } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';

@Component({
  selector: 'app-performance-chart',
  templateUrl: './performance-chart.component.html',
  styleUrls: ['./performance-chart.component.scss'],
})
export class PerformanceChartComponent implements OnInit {
  @Input() dataBarChart;
  @Input() length: number;
  barChartData = [
    {
      data: [],
      label: 'Asset Value',
      stack: 'a',
      backgroundColor: '#0073FF',
      hoverBackgroundColor: ' #0073FF',
      borderColor: '#0073FF',
      hoverBorderColor: '#0073FF',
      barThickness: 12,
      barPercentage: 1,
      minBarLength: 1.5,
    },
    {
      data: [],
      label: 'Subscription',
      stack: 'b',
      backgroundColor: '#546E79',
      hoverBackgroundColor: '#546E79',
      borderColor: '#546E79',
      hoverBorderColor: '#546E79',
      barThickness: 12,
      barPercentage: 1,
      minBarLength: 1.5,
    },
    {
      data: [],
      label: 'Redemption/Distribution',
      stack: 'c',
      backgroundColor: '#F59700',
      hoverBackgroundColor: '#F59700',
      borderColor: '#F59700)',
      hoverBorderColor: '#F59700',
      barThickness: 12,
      barPercentage: 1,
      minBarLength: 1.5,
    },
  ];
  barChartLabels = [];
  barChartOptions: ChartOptions;
  barChartLegend = true;
  barChartType: ChartType = 'bar';
  constructor() {}

  ngOnInit(): void {
    this.barChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: 'y',

      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            callback: function (val: number) {
              if (
                /\s/.test(this.getLabelForValue(val)) &&
                this.getLabelForValue(val).length > 24
              ) {
                const text = this.getLabelForValue(val).split(' ');
                if (text[0].length + text[1].length + text[2].length < 22) {
                  const space = ' ';
                  const text1 = [text[0], text[1], text[2]];
                  const text2 = ['/', text[3], text[4]].join(space);
                  text1.join(space);
                  const newLabel =
                    text2.length < 24
                      ? text1.join(space) + text2
                      : text1.join(space) + text2 + '...';
                  return newLabel.split('/');
                } else {
                  const space = ' ';
                  const text1 = [text[0], text[1]];
                  const text2 = ['/', text[2], text[3]].join(space);
                  text1.join(space);
                  const newLabel = text1.join(space) + text2 + '...';
                  return newLabel.split('/');
                }
              } else {
                return this.getLabelForValue(val);
              }
            },
          },
          afterFit(scale) {
            scale.width = 150;
          },
        },

        x: {
          ticks: {
            callback: (value) => {
              const amonut = String(value);
              return amonut.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
          },
        },
      },
      plugins: {
        tooltip: {
          mode: 'index',
        },
      },
    };
    this.dataBarChart.map((values) => {
      this.barChartData[0]?.data?.push(values.total_fund_asset_value);
      this.barChartLabels?.push(values.name);
      this.barChartData[1]?.data?.push(values.total_subscription);
      this.barChartData[2]?.data?.push(values.total_redemption);
    });
  }
  renderHeigth() {
    if (this.barChartLabels.length < 4) {
      return 300 + 'px';
    } else {
      return this.length * 60 + 'px';
    }
  }
}
