import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from './dashboard.service';
import {
  columnsPositionDetailList,
  columnsPositionDetailNameInTable,
} from './dashboard.const';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BreakpointObserver } from '@angular/cdk/layout';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { delay } from 'rxjs';
import { MsalAuthService } from '@core/msal/msal.auth.service';

@UntilDestroy()
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  checked: boolean = false;
  dataBarChart;
  isOpen = null;
  dataSource: MatTableDataSource<any>;
  colorCharts;
  dataCharts;
  username: string;
  dataTableMobile;
  displayedColumns = columnsPositionDetailList;
  columnsNameInTable = columnsPositionDetailNameInTable;
  breakpointCheck = false;
  total_fund_asset_value: number;
  constructor(
    private service: DashboardService,
    private observer: BreakpointObserver,
    private _msalAuthService: MsalAuthService,
  ) {}
  ngAfterViewInit() {
    // Breakpoint at 1366px
    this.observer
      .observe(['(max-width: 1366px)'])
      .pipe(delay(1), untilDestroyed(this))
      .subscribe((res) => {
        if (res.matches) {
          this.reloadData();
          this.breakpointCheck = true;
        } else {
          this.reloadData();
          this.breakpointCheck = false;
        }
      });
  }
  ngOnInit(): void {
    this.username = this._msalAuthService.currentActiveUser().name;
    this.service.isDataChart$.subscribe((value)=> this.checked=value);
  }
  reloadData() {
    this.service.getClientFundPositionList().subscribe((data) => {
      this.dataTableMobile = this.formatDataTable(data, 'fund');
      this.dataBarChart = this.dataChart(data, 'fund');
      this.total_fund_asset_value = this.dataBarChart.reduce((total, obj) => total + obj.total_fund_asset_value, 0);
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'fund_class.short_name':
            return item.fund_class?.short_name;
          case 'fund_series.fund_series_name':
            return item.fund_series?.fund_series_name;
          case 'currency':
            return item.currency;
          case 'fund.name':
            return item.fund?.name;
          default:
            return item[property];
        }
      };
      this.dataSource.sort = this.sort;
    });
  }
  formatDataTable(data, type) {
    const result = [
      ...data
        .reduce((r, o) => {
          const key = o[type]['name'];
          const item =
            r.get(key) ||
            Object.assign({
              id:o._id,
              fund_name: key,
              data_fund: [],
            });
          item.data_fund.push({
            ...o,
          });
          return r.set(key, item);
        }, new Map())
        .values(),
    ];
    return result;
  }
  dataChart(data, type) {
    const result = [
      ...data
        .reduce((r, o) => {
          const key = o[type]['name'];
          const item =
            r.get(key) ||
            Object.assign(
              {
                name: o[type]['name'],
              },
              { total_redemption: 0 },
              {
                total_fund_asset_value: 0,
              },
              {
                total_subscription: 0,
              },
            );
          item.total_redemption += o.total_redemption_usd;
          item.total_fund_asset_value += o.total_fund_asset_value_usd;
          item.total_subscription += o.total_subscription_usd;
          item.total_redemption =Math.abs(item.total_redemption)
          return r.set(key, item);
        }, new Map())
        .values(),
    ];
    return result;
  }
  onChange(i) {
    this.isOpen = i;
  }
}
