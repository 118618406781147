import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { delay } from 'rxjs';
import { PositionFund } from '../../dashboard.const';
@UntilDestroy()
@Component({
  selector: 'app-fund-position',
  templateUrl: './fund-position.component.html',
  styleUrls: ['./fund-position.component.scss'],
})
export class FundPositionComponent implements OnInit {
  @Input() position: PositionFund;
  point = false;
  constructor(private observer: BreakpointObserver) { }
  ngAfterViewInit() {
    this.observer
      .observe(['(max-width: 800px)'])
      .pipe(delay(1), untilDestroyed(this))
      .subscribe((res) => {
        if (res.matches) {
          this.point = true;
        } else {
          this.point = false;
        }
      });
  }
  ngOnInit(): void {}
}
