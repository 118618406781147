<div
  class="managed-account-line-chart-container"
  *ngIf="init && data?.length > 0"
>
  <div class="header">
    <div class="title">Managed Account</div>
  </div>
  <div class="chart-container">
    <canvas
      baseChart
      [type]="'line'"
      [data]="lineChartData"
      [options]="lineChartOptions"
      [legend]="lineChartLegend"
    >
    </canvas>
  </div>
</div>
<div class="loading">
  <div class="lds-default" *ngIf="!init">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
