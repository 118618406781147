import { Component, Input, OnInit } from '@angular/core';
import { Fund } from 'src/app/dashboard/dashboard.const';

@Component({
  selector: 'app-fund-info',
  templateUrl: './fund-info.component.html',
  styleUrls: ['./fund-info.component.scss'],
})
export class FundInfoComponent implements OnInit {
  @Input() fund: Fund;
  constructor() {}

  ngOnInit(): void {}
}
