<div class="class-chart-container" *ngIf="init">
  <div class="header">
    <div class="title">
      <span>Class NAV/share</span>
    </div>
    <div class="class">
      <button
        mat-button
        [matMenuTriggerFor]="btn"
        class="btn-change-class"
        *ngIf="classPosition.length > 0"
      >
        {{ titleClass }}
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          *ngIf="classPosition.length > 0"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8 11.0008L3 6.00078L3.7 5.30078L8 9.60078L12.3 5.30078L13 6.00078L8 11.0008Z"
            fill="#0073FF"
          />
        </svg>
      </button>
      <span *ngIf="classPosition.length == 0" class="title-chart">
        {{ titleClass }}</span
      >
      <mat-menu #btn="matMenu">
        <mat-list role="list" *ngIf="classPosition.length > 0">
          <mat-list-item role="listitem">
            <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="example-radio-group"
            >
              <mat-radio-button
                color="primary"
                *ngFor="let option of optionClassPosition; let i = index"
                (change)="onChangeClass(option)"
                [value]="option.fund_class.short_name"
                [checked]="i == 0"
              >
                {{ option.fund_class.short_name }} ({{
                  option.fund_class.fund_class_name
                }})
              </mat-radio-button>
            </mat-radio-group>
          </mat-list-item>
        </mat-list>
      </mat-menu>
    </div>
  </div>
  <div>
    <span style="padding: 0 16px">({{ currency }})</span>
    <div class="chart-container">
      <canvas
        baseChart
        [data]="barChartData"
        [options]="barChartOptions"
        [type]="barChartType"
        [plugins]="barChartPlugins"
      >
      </canvas>
    </div>
  </div>
</div>
