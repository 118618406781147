import { Component, Input, OnInit } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { TransactionsByQuarter } from 'src/app/dashboard/dashboard.const';
import { FundsService } from '../../funds.service';
@Component({
  selector: 'app-fund-quarter-chart',
  templateUrl: './fund-quarter-chart.component.html',
  styleUrls: ['./fund-quarter-chart.component.scss'],
})
export class FundQuarterChartComponent implements OnInit {
  @Input() fundID: string;
  constructor(private service: FundsService) {}
  init = false;
  txtByQuarterData: TransactionsByQuarter[] = [];
  public barChartLegend = true;
  public barChartPlugins = [
    {
      id: 'legendMargin',
      beforeInit: function (chart, legend, options) {
        const fitValue = chart.legend.fit;
        chart.legend.fit = function fit() {
          fitValue.bind(chart.legend)();
          return (this.height += 10);
        };
      },
    },
  ];
  public barChartData: ChartConfiguration<'bar'>['data'] = {
    labels: [],
    datasets: [
      {
        data: [],
        label: 'Redemption',
        stack: 'a',
        backgroundColor: '#ef9dae',
        hoverBackgroundColor: '#ef9dae',
        borderColor: '#ef9dae',
        hoverBorderColor: '#ef9dae',
        barThickness: 12,
        barPercentage: 1,
      },
      {
        data: [],
        label: 'Transfer Out',
        stack: 'a',
        backgroundColor: '#ce2764',
        hoverBackgroundColor: '#ce2764',
        borderColor: '#ce2764',
        hoverBorderColor: '#ce2764',
        barThickness: 12,
        barPercentage: 1,
      },
      {
        data: [],
        label: 'Distribution',
        stack: 'a',
        backgroundColor: '#900D12',
        hoverBackgroundColor: '#900D12',
        borderColor: '#900D12',
        hoverBorderColor: '#900D12',
        barThickness: 12,
        barPercentage: 1,
      },
      {
        data: [],
        label: 'Drawdown',
        stack: 'a',
        backgroundColor: '#abb6ff',
        hoverBackgroundColor: '#abb6ff',
        borderColor: '#abb6ff',
        hoverBorderColor: '#abb6ff',
        barThickness: 12,
        barPercentage: 1,
      },
      {
        data: [],
        label: 'Transfer In',
        stack: 'a',
        backgroundColor: '#0073ff',
        hoverBackgroundColor: '#0073ff',
        borderColor: '#0073ff',
        hoverBorderColor: '#0073ff',
        barThickness: 12,
        barPercentage: 1,
      },
      {
        data: [],
        label: 'Subscription',
        stack: 'a',
        backgroundColor: '#004DAA',
        hoverBackgroundColor: '#004DAA',
        borderColor: '#004DAA',
        hoverBorderColor: '#004DAA',
        barThickness: 12,
        barPercentage: 1,
      },
    ],
  };
  public barChartOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {},
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        align: 'start',
        labels: {
          boxWidth: 15,
          boxHeight: 10,
          usePointStyle: true,
          font: {
            size: 12,
          },
          padding: 10,
          textAlign: 'center',
          boxPadding: 10,
        },
      },
    },
  };
  ngOnInit(): void {
    this.service.getFundQuarterChart(this.fundID).subscribe((data) => {
      this.txtByQuarterData = data;
      this.txtByQuarterData.forEach((quarter) => {
        if (
          quarter.redemption ||
          quarter.transfer_out ||
          quarter.distribution ||
          quarter.drawdown ||
          quarter.transfer_in ||
          quarter.subscription
        ) {
          this.barChartData.datasets[0].data.push(quarter.redemption + 1);
          this.barChartData.datasets[1].data.push(quarter.transfer_out + 2);
          this.barChartData.datasets[2].data.push(quarter.distribution + 3);
          this.barChartData.datasets[3].data.push(quarter.drawdown + 4);
          this.barChartData.datasets[4].data.push(quarter.transfer_in + 5);
          this.barChartData.datasets[5].data.push(quarter.subscription + 6);
          this.barChartData.labels.push(
            'Q' + quarter.quarter + ' ' + quarter.year,
          );
        }
      });
      if (this.barChartData.labels.length == 0) {
        this.txtByQuarterData = null;
      }
      this.init = true;
    });
  }
}
