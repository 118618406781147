import { Injectable } from '@angular/core';
import { ApiHttpService } from '@core/api/api-http.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { TRANSACTIONS } from './transactions.const';

@Injectable({
  providedIn: 'root',
})
export class TransactionsService {
  private _filterValue = new BehaviorSubject<any>(null);
  public filterValue$ = this._filterValue.asObservable();
  private dataTransaction = new BehaviorSubject<any>(null);
  public dataTransaction$ = this.dataTransaction.asObservable();
  private excel = new BehaviorSubject<any>(null);
  public excel$ = this.excel.asObservable();
  constructor(private apiService: ApiHttpService) {}
  public getTransactionList(): Observable<any[]> {
    return this.apiService.get(TRANSACTIONS);
  }
  changeFilterValue(value: any): void {
    this._filterValue.next(value);
  }
  nextDataTransaction(value: any): void {
    this.dataTransaction.next(value);
  }
  downnloadExcel() {
    this.excel.next(true);
  }
}
