import { Component, OnInit, ViewChild } from '@angular/core';
import {
  Chart,
  ChartConfiguration,
  ChartDataset,
  ChartOptions,
} from 'chart.js';
import * as moment from 'moment';
import { BaseChartDirective } from 'ng2-charts';
import { ManagedAccountHistory } from '../dashboard.const';
import { DashboardService } from '../dashboard.service';
@Component({
  selector: 'app-managed-account-line-chart',
  templateUrl: './managed-account-line-chart.component.html',
  styleUrls: ['./managed-account-line-chart.component.scss'],
})
export class ManagedAccountLineChartComponent implements OnInit {
  public lineChartData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: [],
        label: 'Total Asset Value USD',
        fill: false,
        tension: 0.5,
        borderColor: '#59C8FF',
        backgroundColor: '#FFFFFF',
        pointBackgroundColor: '#004DAA',
      },
    ],
    labels: [],
  };
  public lineChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {},
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
    },
  };
  public lineChartLegend = false;
  constructor(private service: DashboardService) {}
  managedAccountData = [];
  labels = [];
  init = false;
  seeAll = false;
  data: ManagedAccountHistory[] = [];
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  ngOnInit(): void {
    this.service.getManagedAccountHistory().subscribe((data) => {
      this.data = data;
      data
        .filter(
          (history) => history.total_asset_value_usd && history.statement_date,
        )
        .forEach((history) => {
          this.managedAccountData.push(history.total_asset_value_usd);
          const statement_date = moment(history.statement_date).format(
            'MMM yyyy',
          );
          this.labels.push(statement_date);
        });
      this.lineChartData.datasets[0].data = [...this.managedAccountData];
      this.lineChartData.labels = [...this.labels];
      this.init = true;
    });
  }
  ngAfterViewInit() {
    this.chart?.chart?.resize();
  }
}
