<div class="header-container">
  <!-- Menu icon -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    style="
      fill: rgb(255, 255, 255);
      transform: scaleX(-1);
      msfilter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
    "
    *ngIf="breakpointCheck"
    (click)="sidenav.open()"
  >
    <path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z"></path>
  </svg>
</div>
