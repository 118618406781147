import { Injectable } from '@angular/core';
import { ApiHttpService } from '@core/api/api-http.service';
import { BehaviorSubject, Observable, map } from 'rxjs';
import {  HOLDINGSUMMARYBYISSENT, HOLDINGSUMMARYBYQUARTER, HoldingsSummary, HoldingsSummarybyQuarter } from './holding-summaries.const';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class HoldingSummariesService {
  private _filterValue = new BehaviorSubject<any>(null);
  public filterValue$ = this._filterValue.asObservable();
  constructor(private apiService: ApiHttpService, private _http: HttpClient) {}
  public getHoldingSummaryList(): Observable<HoldingsSummary[]> {
    return this.apiService.get(HOLDINGSUMMARYBYISSENT);
  }
  public getHoldingSummaryListbyQuarter(): Observable<HoldingsSummarybyQuarter[]> {
    return this.apiService.get(HOLDINGSUMMARYBYQUARTER);
  }
  changeFilterValue(value: any): void {
    this._filterValue.next(value);
  }
  downloadPDF(url): any {
    return this._http
      .get(
        url,
        {
          responseType: 'blob',
          observe: 'response',
          headers: new HttpHeaders({
            'x-email':environment.local.email,
            'x-active-directory-local-account-id':environment.local.activeDirectoryLocalAccountId,
            'x-auth-token':environment.core.api.key,
          }),
        },
      )
      .pipe(
        map((response) => ({
          body: response.body,
          contentDisposition: response.headers.get('content-disposition') || '',
          contentType:
            response.headers.get('content-type') || 'application/octet-stream',
        })),
      );
  }
}
