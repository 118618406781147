import { Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationStart, Router } from '@angular/router';
import { MsalAuthService } from '@core/msal/msal.auth.service';

@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss'],
})
export class HomeHeaderComponent implements OnInit {
  @Input() sidenav!: MatSidenav;
  @Input() breakpointCheck!: boolean;
  title;
  username = '';
  constructor(
    private _msalAuthService: MsalAuthService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.username = this._msalAuthService.currentActiveUser().name;

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        if (val.url == '/transactions') {
          this.title = 'Transaction Management';
        }else if(val.url =='/holding-summaries'){
          this.title = 'Holding Summaries';
        } else {
          this.title = `Welcome, ${this.username}`;
        }
      }
    });
  }
}
