import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FundsComponent } from './funds.component';
import { FundCardComponent } from './fund-card/fund-card.component';
import { FundInfoComponent } from './fund-card/fund-info/fund-info.component';
import { FundClassChartComponent } from './fund-card/fund-class-chart/fund-class-chart.component';
import { FundTransactionComponent } from './fund-card/fund-transaction/fund-transaction.component';
import { SharedModule } from '@shared/shared.module';
import { NgChartsModule } from 'ng2-charts';
import { FundQuarterChartComponent } from './fund-card/fund-quarter-chart/fund-quarter-chart.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    FundsComponent,
    FundCardComponent,
    FundInfoComponent,
    FundClassChartComponent,
    FundTransactionComponent,
    FundQuarterChartComponent,
  ],
  imports: [CommonModule, SharedModule, NgChartsModule, FormsModule,ReactiveFormsModule],
})
export class FundsModule {}
