<mat-accordion class="fund-card-container">
  <mat-expansion-panel
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header class="fund-header">
      <div class="fund-header-container">
        <div class="fund-name">{{ position.fund.name }}</div>
        <div class="fund-quick-info" *ngIf="panelOpenState">
          <div class="fund-quick-info-card">
            <span class="quick-info-name">Total Fund Asset Value USD</span>
            <span class="quick-info-number">{{
              position.total_fund_asset_value_usd | number : '1.2-2'
            }}</span>
          </div>
          <div class="fund-quick-info-card" *ngIf="position.fund.investment_structure === 'Commitment'">
            <span class="quick-info-name">Total Remaining Commitment USD</span>
            <span class="quick-info-number">{{
              position.total_remaining_commitment_usd | number : '1.2-2'
            }}</span>
          </div>
          <div class="fund-quick-info-card">
            <span class="quick-info-name">Total Subscription USD</span>
            <span class="quick-info-number">{{
              position.total_subscription_usd | number : '1.2-2'
            }}</span>
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
    <!-- Expansion content -->
    <div class="fund-content-container">
      <app-fund-info [fund]="position.fund"></app-fund-info>
      <div
        class="fund-content-chart"
        *ngIf="position.fund.fund_structure !== 'GP/LP'"
      >
        <app-fund-quarter-chart [fundID]="position.fund._id">
        </app-fund-quarter-chart>
        <app-fund-class-chart
          [classPosition]="position.positions"
          [fundID]="position.fund._id"
        ></app-fund-class-chart>
      </div>
      <div class="download_fund_reports" *ngIf="dataReport.length > 0"  [class.download_fund_reports_mobile]="this.breakpointCheck">
        <div class="title">
          <span>Download fund reports</span>
          <div class="year">
            <span>Year:</span>

            <select (change)="changeDataReport($event.target.value)">
              <option
                *ngFor="let item of yearOptions"
                (selected)="(item.year == yearOptions[0].year ? true : false)"
                [value]="item.year"
              >
                {{ item?.year }}
              </option>
            </select>
          </div>
        </div>
        <div class="content" *ngFor="let data of this.dataReportByYear" [class.content_mobile]="this.breakpointCheck">
          <div class="form_download" *ngFor="let item of data.data" [class.form_download_mobile]="this.breakpointCheck">
            <div class="icon">
              <svg
                width="36"
                height="48"
                viewBox="0 0 36 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="36" height="48" fill="#EAF3FF" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M24.0625 19.8125L19.6875 15.4375C19.5625 15.3125 19.4375 15.25 19.25 15.25H13C12.3125 15.25 11.75 15.8125 11.75 16.5V31.5C11.75 32.1875 12.3125 32.75 13 32.75H23C23.6875 32.75 24.25 32.1875 24.25 31.5V20.25C24.25 20.0625 24.1875 19.9375 24.0625 19.8125ZM19.25 16.75L22.75 20.25H19.25V16.75ZM23 31.5H13V16.5H18V20.25C18 20.9375 18.5625 21.5 19.25 21.5H23V31.5ZM14.25 27.75V29H21.75V27.75H14.25ZM14.25 25.25V24H21.75V25.25H14.25Z"
                  fill="#0060D5"
                />
              </svg>
            </div>
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: calc(100% - 36px);
                box-sizing: border-box;
              "
              [ngStyle]="this.breakpointCheck == true ? {'padding': '0px'} : {'padding':'5px'}"
            >
              <div class="text">{{ item.name }}</div>
              <a  class="btn_download" (click)="download(item.url,item.name)"> Download </a>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="fundTransactionTable">
        <mat-expansion-panel [expanded]="true" class="fund-transaction-header">
          <mat-expansion-panel-header
            ><div header class="title">
              Fund Transactions
            </div></mat-expansion-panel-header
          >
          <div class="filter" [class.filter_mobi] ="breakpointCheck">
            <div class="title">
              <span class="icon"
                ><svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9 14H7C6.44772 14 6 13.5523 6 13V9.205L2.295 5.5C2.10721 5.31332 2.00112 5.05979 2 4.795V3C2 2.44772 2.44772 2 3 2H13C13.5523 2 14 2.44772 14 3V4.795C13.9989 5.05979 13.8928 5.31332 13.705 5.5L10 9.205V13C10 13.5523 9.55228 14 9 14ZM3 3V4.795L7 8.795V13H9V8.795L13 4.795V3H3Z"
                    fill="#0073FF"
                  />
                </svg>
              </span>
              <span class="text">Filter</span>
            </div>
            <div class="filter_form">
              <form [formGroup]="formGroup">
                <div fxLayout="column" class="input">
                  <mat-form-field appearance="fill">
                    <mat-select
                      placeholder="Fund Class"
                      floatlLabel="never"
                      formControlName="fund_class"
                      (selectionChange)="changeFilterValue(formGroup.value)"
                    >
                      <mat-option [value]="null">All</mat-option>
                      <mat-option
                        *ngFor="let item of fundClassOptions"
                        [value]="item.option"
                        >{{ item.option }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div fxLayout="column" class="input">
                  <mat-form-field appearance="fill">
                    <mat-select
                      placeholder="Fund Series"
                      floatlLabel="never"
                      formControlName="fund_series"
                      (selectionChange)="changeFilterValue(formGroup.value)"
                      [disabled] = "isChangeFundClass"
                    >
                      <mat-option [value]="null">All</mat-option>
                      <mat-option
                        *ngFor="let item of fundSeriesOptions"
                        [value]="item.option"
                        >{{ item.option }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </form>
            </div>
          </div>
          <app-fund-transaction
            [fundID]="position.fund._id"
            [fundStructure]="position.fund.fund_structure"
            [dataTable] = "fundTransactionTable"
          ></app-fund-transaction>
        </mat-expansion-panel>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
