import { Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import {  MsalRedirectComponent } from '@azure/msal-angular';
import { FundsComponent } from './funds/funds.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { HoldingSummariesComponent } from './holding-summaries/holding-summaries.component';
export const APP_ROUTES: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
    data: {
      pageTitle: 'Dashboard',
    },
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: {
      pageTitle: 'Dashboard',
    },
  },
  {
    path: 'funds',
    component: FundsComponent,
    data: {
      pageTitle: 'Fund',
    },
  },
  {
    path: 'transactions',
    component: TransactionsComponent,
    data: {
      pageTitle: 'Transactions',
    },
  }, {
    path: 'holding-summaries',
    component: HoldingSummariesComponent,
    data: {
      pageTitle: 'Holding Summaries',
    },
  },
  {
    // Needed for handling redirect after login
    path: 'auth',
    component: MsalRedirectComponent,
  },
  { path: '**', redirectTo: '/dashboard' },
];
