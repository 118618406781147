import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import * as moment from 'moment';
import { BaseChartDirective } from 'ng2-charts';
import { Position } from '../../funds.const';

@Component({
  selector: 'app-fund-class-chart',
  templateUrl: './fund-class-chart.component.html',
  styleUrls: ['./fund-class-chart.component.scss'],
})
export class FundClassChartComponent implements OnInit {
  @Input() classPosition: Position[];
  @Input() fundID: string;
  titleClass = '';
  currency = '';
  init = false;
  optionClassPosition= [];
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  public barChartType: ChartType = 'bar';
  public barChartPlugins = [];
  public barChartData: ChartData<'bar'> = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: '#ECEDEE',
        barThickness: 16,
        borderRadius: 4,
        borderColor: '#AEBCC4',
        borderWidth: 1,
      },
    ],
  };

  constructor() {}

  ngOnInit(): void {
    this.optionClassPosition = this.classPosition.sort((a: Position, b: Position) =>
      (`${a.fund_class.short_name} (${a.fund_class.fund_class_name})`).localeCompare(`${b.fund_class.short_name} (${b.fund_class.fund_class_name})`)
    );
    const values = this.classPosition;
    this.barChartData.labels = [];
    this.barChartData.datasets[0].data = [];
    if (values.length !== 0) {
      this.titleClass = `${values[0].fund_class.short_name} (${values[0].fund_class.fund_class_name})`;
      this.currency = values[0].fund_class.currency;
      if (values[0].net_asset_value.length > 0) {
        values[0].net_asset_value.forEach((nav) => {
          this.barChartData.labels.push(
            moment(nav.nav_date).format('DD/MM/YYYY'),
          );
          this.barChartData.datasets[0].data.push(
            nav.nav_lcy ? nav.nav_lcy : 0,
          );
        });
      } else {
        this.barChartData.labels = [];
        this.barChartData.datasets[0].data = [];
      }
    } else {
      this.titleClass = 'No Classes available for this fund';
    }
    this.init = true;
  }
  onChangeClass(data: Position) {
    this.titleClass = `${data.fund_class.short_name} (${data.fund_class.fund_class_name})`;
    this.currency = data.fund_class.currency;
    this.barChartData.labels = [];
    this.barChartData.datasets[0].data = [];
    if (data.net_asset_value.length > 0) {
      data.net_asset_value.forEach((nav) => {
        this.barChartData.labels.push(
          moment(nav.nav_date).format('DD/MM/YYYY'),
        );
        this.barChartData.datasets[0].data.push(nav.nav_lcy ? nav.nav_lcy : 0);
      });
    } else {
      this.barChartData.labels = [];
      this.barChartData.datasets[0].data = [];
    }
    this.chart?.update();
  }
}
