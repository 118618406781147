import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import { delay } from 'rxjs';
@Component({
  selector: 'app-holding-summaries',
  templateUrl: './holding-summaries.component.html',
  styleUrls: ['./holding-summaries.component.scss'],
})
export class HoldingSummariesComponent implements OnInit {
  breakpointCheck: boolean;
  constructor(private observer: BreakpointObserver) {
    // Breakpoint at 1366px
    this.observer
      .observe(['(max-width: 1366px)'])
      .subscribe((res) => {
        if (res?.matches) {
          this.breakpointCheck = true;
        } else {
          this.breakpointCheck = false;
        }
      });
  }

  ngOnInit(): void {}
}
