import { Component, OnInit } from '@angular/core';
import { FundPosition } from './funds.const';
import { FundsService } from './funds.service';

@Component({
  selector: 'app-funds',
  templateUrl: './funds.component.html',
  styleUrls: ['./funds.component.scss'],
})
export class FundsComponent implements OnInit {
  constructor(private serivce: FundsService) {}
  init = false;
  data: FundPosition[] = [];
  ngOnInit(): void {
    this.serivce.getFundList().subscribe((data) => {
      this.data = data;
      this.init = true;
    });
  }
}
