<!-- 1366px above template -->
<div
  class="transaction-container"
  [class.transaction-container-mobi]="breakpointCheck"
>
  <div class="transaction-table" *ngIf="!breakpointCheck">
    <table
      mat-table
      matSort
      class="transaction-table"
      [dataSource]="dataSource"
      fxFill
      multiTemplateDataRows
    >
      <ng-container matColumnDef="fund_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="label">
          Fund Name
        </th>
        <td mat-cell *matCellDef="let row">
          <div
            class="text-long"
          >
            {{ row?.product?.name ? row?.product?.name : '---' }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="fund_class">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="label">
          Fund Class
        </th>
        <td
          mat-cell
          *matCellDef="let row"
        >
          {{
            row?.fund_class?.fund_class_name
              ? row?.fund_class?.fund_class_name
              : '---'
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="fund_series">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="label">
          Fund Series
        </th>
        <td
          mat-cell
          *matCellDef="let row"
        >
          {{
            row?.fund_series?.fund_series_name
              ? row?.fund_series?.fund_series_name
              : '---'
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="currency">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="label">
          Currency
        </th>
        <td
          mat-cell
          *matCellDef="let row"
        >
          {{ row?.currency ? row?.currency : '---' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="transaction_type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="label">
          Transaction Type
        </th>
        <td
          mat-cell
          *matCellDef="let row"
        >
          {{ row?.transaction_type ? row?.transaction_type : '---' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="label text-end">
          Amount
        </th>
        <td
          mat-cell
          *matCellDef="let row"
          class="font-number"
        >
          {{ row?.amount ? (row?.amount | number : '1.2-2') : '0.00' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="units_of_share">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="label text-end">
          Number of Shares
        </th>
        <td
          mat-cell
          *matCellDef="let row"
          class="font-number"
        >
          {{
            row?.number_of_shares
              ? (row?.number_of_shares | number : '1.2-2')
              : '0.00'
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="offering_price">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="label text-end">
          Offering Price
        </th>
        <td
          mat-cell
          *matCellDef="let row"
          class="font-number"
        >
          {{ row?.offering_price | number : '1.2-2' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="dealing_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="label text-end">
          Dealing Date
        </th>
        <td
          mat-cell
          *matCellDef="let row"
        >
          {{
            row?.dealing_date
              ? (row?.dealing_date | date : 'dd/MM/yyyy')
              : ' ---'
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="label">
          Status
        </th>
        <td
          mat-cell
          *matCellDef="let row"
        >
          <div
            class="transaction-status-container"
            [ngClass]="
              row.status
                ? 'transaction-status-container-' + row.status
                : 'transaction-status-container-null'
            "
          >
            {{ row?.status ? row?.status : '---' }}
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr mat-row class="detail-row"></tr>
    </table>
  </div>
  <div class="custom-paging" [class.custom-paging-not-mobi]="breakpointCheck">
    <div class="go-to-container">
      <mat-form-field *ngIf="!breakpointCheck">
        <span>{{ goTo }}</span>
        <mat-select
          [(ngModel)]="goTo"
          (selectionChange)="goToChange($event.value)"
        >
          <mat-option
            *ngFor="let pageNumber of pageNumbers"
            [value]="pageNumber"
            >{{ pageNumber }}</mat-option
          >
        </mat-select>
        <span>of {{ pageNumbers?.length }} pages</span>
      </mat-form-field>
    </div>
    <mat-paginator
      class="mat-paginator-sticky paging"
      [pageSizeOptions]="[15, 30, 50, 100]"
      (page)="paginationChange($event)"
    >
    </mat-paginator>
  </div>
</div>
<!-- Mobile and Tablet Template -->
<div class="transaction-mobile-container" *ngIf="breakpointCheck">
  <div class="transaction-mobile">
    <div
      class="transaction-mobile-detail"
      *ngFor="let transactionByDate of data | paginate : config"
    >
    <div>
      <div class="date">
        {{ transactionByDate.date }}
      </div>
      <div
        class="container"
        *ngFor="let transaction of transactionByDate.transactions "
      >
        <div class="date-transaction" (click)="onChange(transaction._id)" [class.cursor] = "isOpen !== (transaction._id)">
          <div class="transaction-info">
            <span class="transaction-name">{{
              transaction?.product?.name
            }}</span>
            <span class="transaction-type"
              >Type: {{ transaction?.transaction_type }}</span
            >
          </div>
          <div class="transaction-amount">
            <span class="transaction_amount">{{
              transaction?.amount | number : '1.2-2'
            }}</span>
            <span
              class="transaction-status-container"
              [ngClass]="
                transaction?.status
                  ? 'transaction-status-container-' + transaction?.status
                  : 'transaction-status-container-null'
              "
              >{{ transaction?.status }}</span
            >
          </div>
        </div>
        <div class="detail-info" *ngIf="isOpen == (transaction._id)">
          <div class="fund_class">
            <span>Fund Class</span>
            <span class="content">{{
              transaction?.fund_class?.fund_class_name
                ? transaction?.fund_class?.fund_class_name
                : '---'
            }}</span>
          </div>
          <div class="fund_series">
            <span>Fund Series</span>
            <span class="content">{{
              transaction?.fund_series?.fund_series_name
                ? transaction?.fund_series?.fund_series_name
                : '---'
            }}</span>
          </div>
          <div class="number_of_shares">
            <span>Number of Shares</span>
            <span class="content number">{{
              transaction?.number_of_shares
                ? (transaction?.number_of_shares | number : '1.2-2')
                : '0.00'
            }}</span>
          </div>
          <div class="offering_price">
            <span>Offering Price</span>
            <span class="content number">{{
              transaction?.offering_price
                ? (transaction?.offering_price | number : '1.2-2')
                : '0.00'
            }}</span>
          </div>
          <div class="dealing_date">
            <span>Dealing Date</span>
            <span class="content">{{
              transaction?.dealing_date
                ? (transaction?.dealing_date | date : 'dd/MM/yyyy')
                : '---'
            }}</span>
          </div>
        </div>
        <div class="close" *ngIf="isOpen == (transaction._id)" >
          <svg
            width="36"
            height="20"
            viewBox="0 0 36 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            (click)="isOpen = false"
          >
            <rect y="1" width="36" height="18" rx="9" fill="#EAF3FF" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18 6.25L24.25 12.5L23.375 13.375L18 8L12.625 13.375L11.75 12.5L18 6.25Z"
              fill="#0073FF"
            />
          </svg>
        </div>
      </div>
 
    </div>
 
  </div>
  <div *ngIf="data?.length === 0" style="color: aliceblue;">
    No matching data
  </div>
  <div class="custom-paging-mobile">
    <pagination-template
      #p="paginationApi"
      [id]="config.id"
      (pageChange)="config.currentPage = $event"
    >
      <div class="go-to-container">
        <div class="pages">
          <mat-form-field>
            <span>{{ goTo }}</span>
            <mat-select
              [(ngModel)]="goTo"
              (selectionChange)="p.setCurrent($event.value)"
            >
              <mat-option *ngFor="let page of p.pages" [value]="page.value">
                {{ page.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <span>of {{ p.pages.length }} pages</span>
        </div>
        <div class="pagination">
          <div
            class="pagination-previous"
            (click)="paginationPrevious(p)"
          ></div>
          <div class="pagination-next" (click)="paginationNext(p)"></div>
        </div>
      </div>
    </pagination-template>
  </div>
</div>
