import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { HoldingSummariesTableComponent } from './holding-summaries-table/holding-summaries-table.component';
import { HoldingSummariesFilterComponent } from './holding-summaries-filter/holding-summaries-filter.component';
import { HoldingSummariesComponent } from './holding-summaries.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { NgChartsModule } from 'ng2-charts';
import { NgxPaginationModule } from 'ngx-pagination';
import { FilterModule } from '../pipes/filter/filter.module';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';



@NgModule({
  declarations: [
    HoldingSummariesTableComponent,
    HoldingSummariesFilterComponent,
    HoldingSummariesComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    NgChartsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    FilterModule,
    MatDatepickerModule,
    MatNativeDateModule 
  ],providers: [DatePipe,DecimalPipe,MatDatepickerModule,{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }],
})
export class HoldingSummariesModule { }
