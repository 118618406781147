import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserUtils } from '@azure/msal-browser';
import { APP_ROUTES } from './app.routes';

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(APP_ROUTES, {
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? 'enabledNonBlocking'
          : 'disabled', // Set to enabledBlocking to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
