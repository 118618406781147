<div class="fund-info-container">
  <div class="overview-content-container">
    <div class="content">
      <mat-expansion-panel [expanded]="true" class="item">
        <mat-expansion-panel-header>
          <div header class="title">
            Investment Objective
          </div>
        </mat-expansion-panel-header>
        <div detail class="name" *ngIf="fund.investment_objective">
          {{ fund.investment_objective }}
        </div>
        <div detail *ngIf="!fund.investment_objective">---</div>
      </mat-expansion-panel>
      <div style="border-bottom: 1px solid #ecedee; width: 100%"></div>

      <mat-expansion-panel [expanded]="false" class="item">
        <mat-expansion-panel-header>
          <div header class="title">
            Investment Strategy
          </div>
        </mat-expansion-panel-header>
        <div detail class="name" *ngIf="fund.investment_strategy">
          {{ fund.investment_strategy }}
        </div>
        <div detail *ngIf="!fund.investment_strategy">---</div>
      </mat-expansion-panel>
      <div style="border-bottom: 1px solid #ecedee; width: 100%"></div>
      <mat-expansion-panel [expanded]="false" class="item">
        <mat-expansion-panel-header>
          <div header class="title">
            Investment Opportunity
          </div>
        </mat-expansion-panel-header>
        <div detail class="name" *ngIf="fund.investment_opportunity">
          {{ fund.investment_opportunity }}
        </div>
        <div detail *ngIf="!fund.investment_opportunity">---</div>
      </mat-expansion-panel>
      <div *ngIf="fund?.conflicts_of_interest">
        <div style="border-bottom: 1px solid #ecedee; width: 100%"></div>
        <mat-expansion-panel [expanded]="false" class="item">
          <mat-expansion-panel-header>
            <div header class="title">
              Conflicts of Interest
            </div>
          </mat-expansion-panel-header>
          <div detail class="name" innerHTML="{{ fund.conflicts_of_interest }}">
            {{ fund.conflicts_of_interest }}
          </div>
        </mat-expansion-panel>
      </div>
    </div>
    <div class="content-2">
      <div class="item" *ngIf="fund.inception_date">
        <div header class="title">Inception Date</div>
        <div detail class="name">
          {{ fund.inception_date | date : 'dd/MM/yyyy' : '+0000' }}
        </div>
      </div>
      <div class="item" *ngIf="fund.legal_structure">
        <div header class="title">Legal Structure</div>
        <div detail class="name">
          {{ fund.legal_structure }}
        </div>
      </div>
      <div class="item" *ngIf="fund.domicile">
        <div header class="title">Domicile</div>
        <div detail class="name">
          {{ fund.domicile }}
        </div>
      </div>
      <div class="item" *ngIf="fund.currency">
        <div header class="title">Currency</div>
        <div detail class="name">
          {{ fund.currency }}
        </div>
      </div>
    </div>
    <div class="content-2">
      <div class="item" *ngIf="fund.investment_structure">
        <div header class="title">Investment Structure</div>
        <div detail class="name">
          {{ fund.investment_structure }}
        </div>
      </div>
      <div class="item" *ngIf="fund.asset_class">
        <div header class="title">Asset Class</div>
        <div detail class="name">
          {{ fund.asset_class }}
        </div>
      </div>
      <div class="item" *ngIf="fund.fund_structure">
        <div header class="title">Fund Structure</div>
        <div detail class="name">
          {{ fund.fund_structure }}
        </div>
      </div>
      <div class="item" *ngIf="fund.fund_type">
        <div header class="title">Fund Type</div>
        <div detail class="name">
          {{ fund.fund_type }}
        </div>
      </div>
      <div class="item" *ngIf="fund.fund_setup">
        <div header class="title">Fund Setup</div>
        <div detail class="name">
          {{ fund.fund_setup }}
        </div>
      </div>
      <div class="item">
        <div header class="title">Series Accounting</div>
        <div detail class="name">
          {{ fund.series_accounting ? 'Yes' : 'No' }}
        </div>
      </div>
    </div>
    <div class="content-2">
      <div class="item" *ngIf="fund?.fund_prod_custodian">
        <div header class="title">Custodian</div>
        <div detail class="name custodian">
          {{ fund.fund_prod_custodian }}
        </div>
      </div>
      <div class="item" *ngIf="fund.fund_prod_admin">
        <div header class="title">Administrator</div>
        <div detail class="name">
          {{ fund.fund_prod_admin }}
        </div>
      </div>
      <div class="item" *ngIf="fund.fund_prod_paying_agent">
        <div header class="title">Swiss Paying Agent</div>
        <div detail class="name">
          {{ fund.fund_prod_paying_agent }}
        </div>
      </div>
      <div class="item" *ngIf="fund.swiss_representative_agent">
        <div header class="title">Swiss Representative Agent</div>
        <div detail class="name">
          {{ fund.swiss_representative_agent }}
        </div>
      </div>
      <div class="item" *ngIf="fund.auditor">
        <div header class="title">Auditor</div>
        <div detail class="name">
          {{ fund.auditor }}
        </div>
      </div>
      <div class="item" *ngIf="fund.legal_counsel">
        <div header class="title">Legal Counsel</div>
        <div detail class="name">
          {{ fund.legal_counsel }}
        </div>
      </div>
    </div>
  </div>
</div>
