<div class="fund-container">
  <div class="fund">
    <div class="overview">
      <span class="fund-name">
        {{ position.fund.name }}
      </span>
      <span *ngIf="position?.fund_class">Class : {{ position.fund_class.short_name }}</span>
      <span *ngIf="position?.fund_series">Series : {{ position.fund_series.fund_series_name }}</span>
    </div>
  </div>
  <div class="fund-info">
    <div class="info-container">
      <span>Asset Value</span>
      <span class="number">
        {{ position?.total_fund_asset_value_lcy | number : '1.2-2' }}</span>
    </div>
    <div class="info-container" *ngIf="position.fund.fund_structure !== 'GP/LP'">
      <span>Number of Shares </span>
      <span class="number">{{
        position?.total_number_of_shares | number : '1.2-2'
        }}</span>
    </div>
    <div class="info-container" *ngIf="position.fund.investment_structure === 'Commitment'">
      <span>Commitment</span>
      <span class="number">
        {{ position?.total_commitment_lcy | number : '1.2-2' }}</span>
    </div>
    <div class="info-container">
      <span>Subscription/Contribution</span>
      <span class="number">
        {{ position?.total_subscription_lcy | number : '1.2-2' }}</span>
    </div>
    <div class="info-container">
      <span>Redemption/Distribution</span>
      <span class="number">
        {{ position?.total_redemption_lcy | number : '1.2-2' }}</span>
    </div>
    <div class="info-container" *ngIf="position.fund.investment_structure === 'Commitment'">
      <span>Remaining Commitment</span>
      <span class="number">
        {{ position?.total_remaining_commitment_usd | number : '1.2-2' }}</span>
    </div>
    <div class="info-container" *ngIf="position.fund.fund_structure !== 'GP/LP'">
      <span>NAV per Share</span>
      <span class="number">{{ position?.fund_nav | number : '1.2-2' }}</span>
    </div>
    <div class="info-container">
      <span>Currency</span>
      <span class="number">{{ position?.currency ? position?.currency : '---' }}</span>
    </div>
    <div class="info-container">
      <span>Valuation Date</span>
      <span class="number">{{ position?.nav_date | date : 'dd/MM/yyyy' }}</span>
    </div>
  </div>
</div>
