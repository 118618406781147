<div class="dashboard-container">
  <div class="information">
    <div class="name">
      <span>Welcome, {{ username }}</span>
    </div>
    <div class="total_fund">
      <span>Total Fund Asset Value:</span>
      <div class="total">
        <span>USD</span>
        <span class="font_number">
          {{ total_fund_asset_value | number : '1.2-2' }}
        </span>
      </div>
    </div>
  </div>
  <div class="first-columns">
    <div class="performance" *ngIf="this.dataBarChart?.length > 0">
      <div class="header">
        <div class="title">Performance</div>
        <div class="legend_chart">
          <span
            ><svg
              width="10"
              height="11"
              viewBox="0 0 10 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="0.5" width="10" height="10" rx="5" fill="#0073FF" />
            </svg>
            Asset Value
          </span>
          <span style="padding-left: 30px">
            <svg
              width="10"
              height="11"
              viewBox="0 0 10 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="0.5" width="10" height="10" rx="5" fill="#546E79" />
            </svg>
            Subscription
          </span>
          <span style="padding-left: 30px">
            <svg
              width="10"
              height="11"
              viewBox="0 0 10 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="0.5" width="10" height="10" rx="5" fill="#F59700" />
            </svg>
            Redemption/Distribution
          </span>
        </div>
      </div>
      <app-performance-chart
        [dataBarChart]="dataBarChart"
        [length]="this.dataBarChart.length"
      ></app-performance-chart>
    </div>
    <div class="chart_by_quarter" [ngStyle]="checked === false ? {'background':'none'}:{'background':'white'}">
      <app-chart-by-quarter
        [length]="this.dataBarChart?.length"
      ></app-chart-by-quarter>
    </div>
  </div>

  <div class="table" *ngIf="!breakpointCheck">
    <table
      mat-table
      matSort
      class="transaction"
      [dataSource]="dataSource"
      fxFill
      multiTemplateDataRows
    >
      <ng-container matColumnDef="fund.name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="label">
          Fund Name
        </th>
        <td mat-cell *matCellDef="let row">
          <div class="text-long">
            {{ row?.fund?.name ? row?.fund?.name : '---' }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="fund_class.short_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="label">
          Fund Class
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row?.fund_class ? row?.fund_class?.short_name : '---' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="fund_series.fund_series_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="label">
          Fund Series
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row?.fund_series ? row?.fund_series?.fund_series_name : '---' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="currency">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="label text-end"
        >
          CCY
        </th>
        <td mat-cell *matCellDef="let row" class="number">
          {{ row?.currency ? row?.currency : '---' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="total_fund_asset_value_lcy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="label">
          Asset Value (LCY)
        </th>
        <td mat-cell *matCellDef="let row" class="number">
          {{
            row?.total_fund_asset_value_lcy
              ? (row?.total_fund_asset_value_lcy | number : '1.2-2')
              : '0.00'
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="total_fund_asset_value_usd">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="label">
          Asset Value (USD)
        </th>
        <td mat-cell *matCellDef="let row" class="number">
          {{
            row?.total_fund_asset_value_usd
              ? (row?.total_fund_asset_value_usd | number : '1.2-2')
              : '0.00'
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="total_number_of_shares">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="label text-end"
        >
          Number of Shares
        </th>
        <td mat-cell *matCellDef="let row" class="number">
          {{
            row?.total_number_of_shares
              ? (row?.total_number_of_shares | number : '1.2-2')
              : '0.00'
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="total_commitment_lcy">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="label text-end"
        >
          Commitment
        </th>
        <td mat-cell *matCellDef="let row" class="number">
          {{
            row?.total_commitment_lcy
              ? (row?.total_commitment_lcy | number : '1.2-2')
              : '0.00'
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="total_subscription_lcy">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="label text-end"
        >
          Subscription/Contribution
        </th>
        <td mat-cell *matCellDef="let row" class="number">
          {{
            row?.total_subscription_lcy
              ? (row?.total_subscription_lcy | number : '1.2-2')
              : '0.00'
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="total_redemption_lcy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="label">
          Redemption/Distribution
        </th>
        <td mat-cell *matCellDef="let row">
          <div class="number">
            {{
              row.total_redemption_lcy
                ? row.total_redemption_lcy > 0
                  ? (row?.total_redemption_lcy | number : '1.2-2')
                  : (row?.total_redemption_lcy * -1 | number : '1.2-2')
                : '0.00'
            }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="total_remaining_commitment_lcy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="label">
          Remaining Commitment
        </th>
        <td mat-cell *matCellDef="let row">
          <div class="number">
            {{
              row?.total_remaining_commitment_lcy
                ? (row?.total_remaining_commitment_lcy | number : '1.2-2')
                : '0.00'
            }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="fund_nav">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="label">
          NAV per share
        </th>
        <td mat-cell *matCellDef="let row">
          <div class="number">
            {{ row?.fund_nav ? (row?.fund_nav | number : '1.2-2') : '0.00' }}
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr mat-row class="detail-row"></tr>
    </table>
  </div>
  <div class="table_mobile" *ngIf="breakpointCheck">
    <div *ngFor="let item of dataTableMobile; let i = index">
      <div>
        <span class="fund_name">{{ item.fund_name }}</span>
        <div *ngFor="let option of item.data_fund" class="content">
          <div
            class="content_first"
            (click)="onChange(option._id)"
            [class.cursor]="isOpen !== option._id"
          >
            <div class="fund_class">
              <label for="">Fund Class</label>
              <span>{{
                option.fund_class ? option.fund_class?.fund_class_name : '---'
              }}</span>
            </div>
            <div class="fund_series">
              <label for="">Fund Series</label>
              <span>{{
                option.fund_series
                  ? option.fund_series?.fund_series_name
                  : '---'
              }}</span>
            </div>
          </div>
          <div
            class="content_second"
            *ngIf="isOpen == option._id && isOpen !== null"
          >
            <div class="currency">
              <label for="">CCY</label>
              <span>{{ option.currency }}</span>
            </div>
            <div class="total_fund_asset_value_lcy">
              <label for="">Asset Value (LCY)</label>
              <span class="font_number">
                {{
                  option?.total_fund_asset_value_lcy
                    ? (option?.total_fund_asset_value_lcy | number : '1.2-2')
                    : '0.00'
                }}</span
              >
            </div>
            <div class="total_fund_asset_value_usd">
              <label for="">Asset Value (USD)</label>
              <span class="font_number">
                {{
                  option?.total_fund_asset_value_usd
                    ? (option?.total_fund_asset_value_usd | number : '1.2-2')
                    : '0.00'
                }}</span
              >
            </div>
            <div class="total_number_of_shares">
              <label for="">Number of Shares</label>
              <span class="font_number">
                {{
                  option?.total_number_of_shares
                    ? (option?.total_number_of_shares | number : '1.2-2')
                    : '0.00'
                }}</span
              >
            </div>
            <div class="total_commitment_lcy">
              <label for=""> Commitment</label>
              <span class="font_number">
                {{
                  option?.total_commitment_lcy
                    ? (option?.total_commitment_lcy | number : '1.2-2')
                    : '0.00'
                }}</span
              >
            </div>
            <div class="total_subscription_lcy">
              <label for=""> Subscription/Contribution</label>
              <span class="font_number">
                {{
                  option?.total_subscription_lcy
                    ? (option?.total_subscription_lcy | number : '1.2-2')
                    : '0.00'
                }}</span
              >
            </div>
            <div class="total_redemption_lcy">
              <label for=""> Redemption/Distribution</label>
              <span class="font_number">
                {{
                  option?.total_redemption_lcy
                    ? (option?.total_redemption_lcy | number : '1.2-2')
                    : '0.00'
                }}</span
              >
            </div>
            <div class="total_remaining_commitment_lcy">
              <label for=""> Remaining Commitment</label>
              <span class="font_number">
                {{
                  option?.total_remaining_commitment_lcy
                    ? (option?.total_remaining_commitment_lcy
                      | number : '1.2-2')
                    : '0.00'
                }}</span
              >
            </div>
            <div class="fund_nav">
              <label for=""> NAV per share</label>
              <span class="font_number">
                {{
                  option?.fund_nav
                    ? (option?.fund_nav | number : '1.2-2')
                    : '0.00'
                }}</span
              >
            </div>
          </div>
          <div
            class="close cursor"
            *ngIf="isOpen == option._id && isOpen !== null"
          >
            <svg
              width="36"
              height="20"
              viewBox="0 0 36 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              (click)="onChange(null)"
            >
              <rect y="1" width="36" height="18" rx="9" fill="#EAF3FF" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18 6.25L24.25 12.5L23.375 13.375L18 8L12.625 13.375L11.75 12.5L18 6.25Z"
                fill="#0073FF"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="managed_account">
    <app-managed-account-line-chart></app-managed-account-line-chart>
  </div>
</div>
