import { ErrorHandler, Injector, Injectable } from '@angular/core';
import { LoggingService } from '../logging/logging.service';

@Injectable()
export class ApplicationInsightsErrorHandler implements ErrorHandler{

  constructor(private injector : Injector)
  {
  }

  handleError(error: any): void {
    this.injector.get<LoggingService>(LoggingService).logException(error);
    console.log(error);
  }
}