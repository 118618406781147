import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { delay } from 'rxjs';
import * as moment from 'moment';
import { FundPosition } from '../funds.const';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FundsService } from '../funds.service';
import { saveAs } from 'file-saver';
@UntilDestroy()
@Component({
  selector: 'app-fund-card',
  templateUrl: './fund-card.component.html',
  styleUrls: ['./fund-card.component.scss'],
})
export class FundCardComponent implements OnInit {
  panelOpenState = false;
  formGroup;
  fundClassOptions = [];
  fundSeriesOptions = [];
  @Input() position: FundPosition;
  breakpointCheck = false;
  isChangeFundClass = true;
  yearOptions = [];
  dataReport = [];
  dataReportByYear = [];
  fundTransactionTable;
  constructor(
    private _fb: UntypedFormBuilder,
    private service: FundsService,
    private observer: BreakpointObserver,
  ) {}
  ngAfterViewInit() {
    // Breakpoint at 1366px
    this.observer
      .observe(['(max-width: 1366px)'])
      .pipe(delay(1), untilDestroyed(this))
      .subscribe((res) => {
        if (res.matches) {
          this.breakpointCheck = true;
        } else {
          this.breakpointCheck = false;
        }
      });
  }
  ngOnInit(): void {
    this.service
      .getFundTransaction(this.position.fund._id)
      .subscribe((data) => {
        this.fundTransactionTable = data;
        if (data.length > 0) {
          data.map((value) => {
            if (value.fund_class) {
              this.fundClassOptions.push({
                option: value.fund_class.fund_class_name,
              });
            }
          });
          this.fundClassOptions = this.formatDataFilter(
            this.fundClassOptions,
            'option',
          );
          this.formGroup
            .get('fund_class')
            .valueChanges.subscribe((value_fund_class) => {
              if (value_fund_class) {
                this.isChangeFundClass = false;
                data.map((value) => {
                  if (
                    value.fund_series &&
                    value.fund_class.fund_class_name === value_fund_class
                  ) {
                    this.fundSeriesOptions.push({
                      option: value.fund_series?.fund_series_name,
                    });
                  } else {
                    this.fundSeriesOptions = [];
                    this.formGroup.get('fund_series').setValue(null);
                  }
                });
                this.fundSeriesOptions = this.formatDataFilter(
                  this.fundSeriesOptions,
                  'option',
                );
              } else {
                this.isChangeFundClass = true;
                this.formGroup.get('fund_series').setValue(null);
              }
            });
        }
      });
    this.formGroup = this._createFormGroup();
    if (this.position.documents.length > 0) {
      const dataByYear = this.formDataByYear(this.position.documents, '_id');
      if (dataByYear)
        this.dataReport = this.formatDataReport(dataByYear, 'year');
      this.yearOptions = this.dataReport;
      this.dataReportByYear = this.dataReport.filter(
        (data) => data.year == this.dataReport[0].year,
      );
    }
  }
  private _createFormGroup(): UntypedFormGroup {
    return this._fb.group({
      fund_class: [null],
      fund_series: [null],
    });
  }
  changeFilterValue(filterValue) {
    event.preventDefault();
    this.service.changeFilterValue(filterValue, this.position.fund._id);
  }
  formDataByYear(data, type) {
    const result = [
      ...data
        .reduce((r, o) => {
          const key = o[type];
          const item =
            r.get(key) ||
            Object.assign(
              {},
              {
                year: o.reference_date
                  ? moment(o.reference_date).format('YYYY')
                  : null,
                data: {},
              },
            );
          if (item.year) {
            if (o.type == 'Quarterly Report') {
              const quarterly = moment(o.reference_date).quarter();
              const year = item.year;
              const name = `Q${quarterly} - ${year}`;
              item.data = {
                name: name,
                url: o.url,
                id: o._id,
              };
            } else if (o.type == 'Monthly Report') {
              const monthly = moment(o.reference_date).month();
              const year = item.year;
              const name = `T${monthly} - ${year}`;
              item.data = {
                name: name,
                url: o.url,
                id: o._id,
              };
            }
          }
          return r.set(key, item);
        }, new Map())
        .values(),
    ];
    const formdata = result.filter((r) => r.year !== null);
    formdata.sort((a, b) => {
      return a.data.name.localeCompare(b.data.name);
    });
    return formdata;
  }
  formatDataReport(data, type) {
    const result = [
      ...data
        .reduce((r, o) => {
          const key = o[type];
          const values = [];
          const item =
            r.get(key) ||
            Object.assign(
              {},
              {
                year: o[type],
              },
              {
                data: [],
              },
            );
          item.data.push(o.data);
          return r.set(key, item);
        }, new Map())
        .values(),
    ];
    result.sort((a, b) => {
      return b.year.localeCompare(a.year);
    });
    return result;
  }
  formatDataFilter(data, type) {
    const result = [
      ...data
        .reduce((r, o) => {
          const key = o[type];
          const item =
            r.get(key) ||
            Object.assign(
              {},
              {
                option: o[type],
              },
            );
          return r.set(key, item);
        }, new Map())
        .values(),
    ];
    if (result.length > 0) {
      let list = result.sort((a, b) => {
        return a.option.localeCompare(b.option);
      });
      return list;
    }
    return result;
  }
  changeDataReport(value) {
    this.dataReportByYear = this.dataReport.filter(
      (data) => Number(data.year) === Number(value),
    );
  }
  download(url,name){
    this.service.downloadDocument(url).subscribe((res) => {
      const dateFormat = new Date();
      const filename = name + dateFormat;
      saveAs(new Blob([res.body],{ type: res.contentType }), filename);
    });
  }
}
