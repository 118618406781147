<div class="holding-summary-table" *ngIf="dataSouce">
  <div class="holding-summary-table-container" *ngFor="let row of dataSouce">
    <div class="content">
      <div class="valuation_date">
        <span class="title">Valuation Date</span>
        <span class="value">{{
          row.valuation_date | date : 'dd/MM/yyyy'
        }}</span>
      </div>
      <div class="total_asset_value">
        <span class="title">Total Asset Value</span>
        <span class="value number">{{
          row.total_asset_value | number : '1.2-2'
        }}</span>
      </div>
    </div>
    <div class="btn">
      <div class="btn-view" (click)="viewHoldingSummray(row)">
        <span>View</span>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.5 7.8C14.3 4.7 11.3 2.6 8 2.5C4.7 2.6 1.7 4.7 0.5 7.8V8.1C1.7 11.2 4.6 13.3 8 13.4C11.3 13.3 14.3 11.2 15.5 8.1V7.8ZM5 8C5 6.3 6.3 5 8 5C9.7 5 11 6.3 11 8C11 9.7 9.7 11 8 11C6.3 11 5 9.7 5 8ZM6 8C6 9.1 6.9 10 8 10C9.1 10 10 9.1 10 8C10 6.9 9.1 6 8 6C6.9 6 6 6.9 6 8ZM1.5 8C2.6 10.5 5.3 12.5 8 12.5C10.6 12.5 13.4 10.5 14.5 8C13.4 5.5 10.7 3.5 8 3.5C5.3 3.5 2.5 5.5 1.5 8Z"
            fill="#0073FF"
          />
        </svg>
      </div>
      <div class="btn-download" (click)="downloadHoldingSummary(row)">
        <span>Dowload</span>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13 7L12.3 6.3L8.5 10.1V1H7.5V10.1L3.7 6.3L3 7L8 12L13 7ZM13 12V14H3V12H2V14C2 14.6 2.4 15 3 15H13C13.6 15 14 14.6 14 14V12H13Z"
            fill="#0073FF"
          />
        </svg>
      </div>
    </div>
  </div>
  <div *ngIf="dataSouce.length == 0">
    <span style="color:white"> Not Data</span>
  </div>
</div>
