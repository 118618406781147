<div class="line-chart" *ngIf="init">
  <div class="title">Fund Asset Value by Quarter</div>
  <div class="xPlane" *ngIf="getLegendCallback">
    <div
      class="xPlane-sub"
      *ngFor="let j of getLegendCallback.chartData; let i = index"
    >
      <button
        class="btn-chart"
        (click)="onSelect(i)"
        [style.background-color]="backgroundColors[i]"
        [style.border]="'2px solid ' + j.backgroundColor"
        matTooltipClass="tooltip-name"
        [matTooltip]="j.label"
        [style.opacity]="j.hidden == true ? 0.5 : 1"
      >
        <svg
          width="10"
          height="11"
          viewBox="0 0 10 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            y="0.5"
            width="10"
            height="10"
            rx="5"
            [attr.fill]="j.backgroundColor"
          />
        </svg>

        <span class="label-chart">{{ j.label }}</span>
      </button>
    </div>
    
  </div>
  <div
    class="cavas"
    style="width: 100%; "
    [ngStyle]="{ 'max-height': renderHeigth(), 'height': renderHeigth() }"
  >
    <canvas
      #myCanvas
      id="myCanvas"
      baseChart
      [type]="chartType"
      [datasets]="chartData"
      [labels]="chartLabels"
      [options]="chartOptions"
      [plugins]="pieChartPlugins"
      [legend]="false"
    ></canvas>
  </div>
</div>
