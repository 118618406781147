import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { ManagedAccountValueComponent } from './managed-account-value/managed-account-value.component';
import { TxtQuarterChartComponent } from './txt-quarter-chart/txt-quarter-chart.component';
import { NgChartsModule } from 'ng2-charts';
import { ManagedAccountLineChartComponent } from './managed-account-line-chart/managed-account-line-chart.component';
import { ClientFundPositionComponent } from './client-fund-position/client-fund-position.component';
import { FundPositionComponent } from './client-fund-position/fund-position/fund-position.component';
import { PerformanceChartComponent } from './performance-chart/performance-chart.component';
import { ChartByQuarterComponent } from './chart-by-quarter/chart-by-quarter.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [
    DashboardComponent,
    ManagedAccountValueComponent,
    TxtQuarterChartComponent,
    ManagedAccountLineChartComponent,
    ClientFundPositionComponent,
    FundPositionComponent,
    PerformanceChartComponent,
    ChartByQuarterComponent,
  ],
  imports: [CommonModule, NgChartsModule, MatTooltipModule, SharedModule],
})
export class DashboardModule {}
