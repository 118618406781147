<div class="txt-quarter-container" *ngIf="init && txtByQuarterData?.length > 0">
  <div class="header">
    <div class="title">Transactions by Quarter</div>
  </div>
  <div class="chart-container">
    <canvas
      baseChart
      [data]="barChartData"
      [options]="barChartOptions"
      [plugins]="barChartPlugins"
      [legend]="barChartLegend"
      [type]="'bar'"
    >
    </canvas>
  </div>
</div>
<div class="loading">
  <div class="lds-default" *ngIf="!init">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
