<div class="fund-position-container" *ngIf="init && data?.length > 0">
  <div class="header">
    <span>Fund Holdings</span>
  </div>
  <div class="body">
    <ng-container *ngFor="let position of data">
      <app-fund-position [position]="position"></app-fund-position>
    </ng-container>
  </div>
</div>
<div class="loading">
  <div class="lds-default" *ngIf="!init">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
