import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { HoldingSummariesService } from 'src/app/holding-summaries/holding-summaries.service';
import { ChartDataset } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { DashboardService } from '../dashboard.service';
@Component({
  selector: 'app-chart-by-quarter',
  templateUrl: './chart-by-quarter.component.html',
  styleUrls: ['./chart-by-quarter.component.scss'],
})
export class ChartByQuarterComponent implements OnInit {
  @ViewChild('myCanvas')
  public canvas: ElementRef;
  public context: CanvasRenderingContext2D;
  public chartType: string = 'line';
  public init = false;
  @Input() length: number;
  backgroundColor = [
    'rgb(244, 64, 105)',
    'rgb(74, 152, 255)',
    'rgb(247, 199, 55)',
    'rgb(207, 212, 222)',
    'rgb(80, 207, 207)',
    'rgb(122, 176, 211)',
  ];
  backgroundColors = [
    '#fce0e7',
    '#c8d3ff',
    '#f7e49c69',
    '#e7e9ee6b',
    '#b3e7e775',
    '#c1d7e97a',
  ];
  @ViewChild(BaseChartDirective) chartComponent: BaseChartDirective;
  getLegendCallback = (function (self) {
    return self;
  })(this);
  chartLabels;
  chartData: ChartDataset[] = [];
  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          callback: function (label, index, labels) {
            const format = Number(label).toFixed(0);
            return format.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          },
        },
      },
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const format = Number(tooltipItem.value).toFixed(2);
          let name = '';
          const label = data.datasets[tooltipItem.datasetIndex].label;
          if (label.length > 80) {
            name = label.slice(0, 80) + '...';
          } else {
            name = data.datasets[tooltipItem.datasetIndex].label;
          }
          return (
            name + ':' + ' ' + format.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          );
        },
      },
    },
    legend: {
      align: 'start',
      labels: {
        boxWidth: 10,
        fontSize: 13,
        usePointStyle: true,
        padding: 20,
      },
    },
    legendCallback: this.getLegendCallback,
  };
  pieChartPlugins = [
    {
      beforeInit: function (chart, options) {
        chart.legend.afterFit = function () {
          this.height += 20;
        };
      },
    },
  ];
  constructor(
    private _service: HoldingSummariesService,
    private service: DashboardService,
  ) {}
  ngOnInit(): void {
    this._service.getHoldingSummaryListbyQuarter().subscribe((data) => {
      if (data.length > 0) {
        const lineData: ChartDataset[] = this.formatData(data, 'fund_name');
        lineData.forEach((line) => {
          this.chartData.push(line);
        });
        this.service.checkDataChart(true);
        this.init = true;
      }
    });
  }
  formatData(dataArray: any[], type) {
    const quarter = [];
    for (let i of dataArray) {
      for (let j of i.data) {
        if (!quarter.includes(j.quarter)) quarter?.push(j.quarter);
      }
    }
    this.chartLabels = quarter;
    const result = dataArray.map((record) => {
      let chartData = [];
      this.chartLabels.forEach((quarter) => {
        const a = record.data.find((element) => element.quarter == quarter);
        if (a) {
          chartData.push(Number.parseFloat(a.asset_value_usd.toFixed(2)));
        }
      });
      return {
        label: record.fund_name,
        fill: false,
        hidden: false,
        pointHoverBorderWidth: 2,
        pointRadius: 5,
        pointHitRadius: 20,
        backgroundColor: '',
        data: chartData,
      };
    });
    for (let i = 0; i < result.length; i++) {
      if (i == 0) {
        result[0].hidden = false;
      } else {
        result[i].hidden = true;
      }
      result[i].backgroundColor = this.backgroundColor[i];
    }

    return result;
  }
  onSelect(indexItem): void {
    const informationDataChart = this.chartComponent;

    if (this.chartData[indexItem].hidden === false) {
      this.chartData[indexItem].hidden = true;
    } else {
      this.chartData[indexItem].hidden = false;
    }
    informationDataChart.update();
  }
  renderHeigth() {
    let number = this.chartData.length > 3 ? this.chartData.length / 3 : 1;
    let integerResult = Math.floor(number);
    if(number <= integerResult){
      number = integerResult;
    }else{
      number = integerResult + 1
    }
    if (this.length < 4) {
      if(number == 1){
        return 257 + 'px';
      }
      if(number == 2){
        return 300 - (number * 33 + (number - 1) * 22)+ 'px';
      }
      if(number > 2){
        return 300 - (number * 30 + (number - 1) * 22)+ 'px';
      }
    } else {
      return this.length * 60 - (number * 30 + (number - 1) * 22) + 'px';
    }
  }
}
