import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { HomepageComponent } from './homepage/homepage.component';
import { RouterModule } from '@angular/router';
import { ClipboardModule } from 'ngx-clipboard';

@NgModule({
  declarations: [HomepageComponent],

  imports: [CommonModule, MaterialModule, RouterModule,ClipboardModule],

  exports: [HomepageComponent, MaterialModule, RouterModule,ClipboardModule],
})
export class SharedModule {}
