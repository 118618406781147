import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TransactionsFilterComponent } from './transactions-filter/transactions-filter.component';
import { TransactionsTableComponent } from './transactions-table/transactions-table.component';
import { TransactionsComponent } from './transactions.component';
import { SharedModule } from '@shared/shared.module';
import { NgChartsModule } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { FilterModule } from '../pipes/filter/filter.module';

@NgModule({
  declarations: [
    TransactionsFilterComponent,
    TransactionsTableComponent,
    TransactionsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    NgChartsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    FilterModule,
  ],
  providers: [DatePipe],
})
export class TransactionsModule {}
