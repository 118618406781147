import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TransactionsService } from '../transactions.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { BreakpointObserver } from '@angular/cdk/layout';
import { delay } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { groupTransactionByDate } from '@shared/utils/groupTransaction';
import {  PaginationInstance } from 'ngx-pagination';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import * as moment from 'moment';
import { FilterPipe } from 'src/app/pipes/filter.pipe';
@UntilDestroy()
@Component({
  selector: 'app-transactions-table',
  templateUrl: './transactions-table.component.html',
  styleUrls: ['./transactions-table.component.scss'],
})
export class TransactionsTableComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  breakpointCheck: boolean = false;
  goTo: number = 1;
  pageNumbers: number[];
  pageIndex: number = 0;
  length: number;
  pageSize: number = 15;
  data;
  dataTransaction;
  isOpen = false;
  filterValue;
  displayedColumns = [
    'fund_name',
    'fund_class',
    'fund_series',
    'currency',
    'transaction_type',
    'amount',
    'units_of_share',
    'offering_price',
    'dealing_date',
    'status',
  ];
  transactionName = [
    'Fund Name',
    'Fund Class',
    'Fund Series',
    'CCY',
    'Transaction Type',
    'Amount',
    'Units Of Share',
    'Offering Price',
    'Dealing Date',
    'Status',
  ];
  public config: PaginationInstance = {
    id: 'custom',
    itemsPerPage: 5,
    currentPage: 1,
  };
  transactionFilter: any = {};
  excelData;
  constructor(
    private service: TransactionsService,
    private observer: BreakpointObserver,
    private datePipe: DatePipe,
    private _snackBar: MatSnackBar,
    private filterPipe: FilterPipe,
  ) {}
  ngAfterViewInit() {
    // Breakpoint at 1366px
    this.observer
      .observe(['(max-width: 1366px)'])
      .pipe(delay(1), untilDestroyed(this))
      .subscribe((res) => {
        if (res.matches) {
          this.breakpointCheck = true;
        } else {
          this.breakpointCheck = false;
        }
      });
  }

  ngOnInit(): void {
    this.reload();
    this.service.filterValue$.subscribe((value) => {
      if (!!value && !!this.dataSource) {
        if (value.fund_series !== null) {
          this.transactionFilter = {
            product: { name: value.fund_name },
            fund_class: value.fund_class
              ? { fund_class_name: value.fund_class }
              : null,
            fund_series: value.fund_series
              ? { fund_series_name: value.fund_series }
              : null,
            transaction_type: value.transaction_type,
          };
        } else {
          this.transactionFilter = {
            product: { name: value.fund_name },
            fund_class: value.fund_class
              ? { fund_class_name: value.fund_class }
              : null,

            transaction_type: value.transaction_type,
          };
        }
        let filterData = this.filterPipe.transform(
          this.dataTransaction,
          this.transactionFilter,
        );
        this.data = groupTransactionByDate(filterData);
        if (filterData) {
          this.config.currentPage = 1;
          this.goTo = 1;
        }
        this.filterValue = value;
        this.dataSource.filter = JSON.stringify(this.filterValue);
        this.length = this.dataSource.filteredData.length;
        this.updateGoto();
      }
    });
    this.service.excel$.subscribe((value) => {
      if (this.dataSource && value) {
        this._snackBar.open(
          'Request received, the file is being prepared',
          'X',
          {
            duration: 2000,
            panelClass: ['success-snackbar'],
          },
        );
        this.excelData = [];
        this.createExcelData();
        const fileName =
          'Transactions_' +
          this.datePipe.transform(new Date(), 'ddMMyyyy') +
          '.xlsx';

        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Transactions', {
          views: [{ state: 'frozen', ySplit: 1 }],
        });
        const headerList = [...this.transactionName];
        const headerRow = worksheet.addRow(headerList);
        for (let column = 1; column < headerList.length + 1; column++) {
          worksheet.getColumn(column).width = 40;
        }
        headerRow.eachCell((cell) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFFFF00' },
            bgColor: { argb: 'FF0000FF' },
          };
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
        });
        this.excelData.forEach((position) => {
          worksheet.addRow(position);
        });
        for (let column = 1; column < +1; column++) {
          worksheet.getColumn(column).width = 40;
        }
        workbook.xlsx.writeBuffer().then((data) => {
          const blob = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          fs.saveAs(blob, fileName);
        });
      }
    });
  }

  reload() {
    this.service.getTransactionList().subscribe((data) => {
      this.service.nextDataTransaction(data);
      this.dataSource = new MatTableDataSource(data);
      this.data = groupTransactionByDate(data);
      this.dataTransaction = data;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.length = this.dataSource.filteredData.length;
      this.dataSource.filterPredicate = this.filterPredicate;
      this.updateGoto();
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'fund_name':
            return item.product?.name;
          case 'fund_class':
            return item.fund_class?.fund_class_name;
          case 'fund_series':
            return item.fund_series?.fund_series_name;
          case 'amount':
            return item?.amount ? item?.amount : 0;
          case 'units_of_share':
            return item?.number_of_shares ? item?.number_of_shares : 0;
          default:
            return item[property];
        }
      };
    });
  }
  filterPredicate = (items, filter: string) => {
    if (this.filterValue) {
      let isMatched =
        (!!this.filterValue?.fund_name
          ? items.product?.name?.toLowerCase() ===
            this.filterValue?.fund_name.toLowerCase()
          : true) &&
        (!!this.filterValue?.fund_class
          ? items.fund_class?.fund_class_name?.toLowerCase() ===
            this.filterValue?.fund_class.toLowerCase()
          : true) &&
        (!!this.filterValue?.fund_series
          ? items.fund_series?.fund_series_name?.toLowerCase() ===
            this.filterValue?.fund_series.toLowerCase()
          : true) &&
        (!!this.filterValue?.transaction_type
          ? items?.transaction_type?.toLowerCase() ===
            this.filterValue?.transaction_type.toLowerCase()
          : true);

      return isMatched;
    }
    return true;
  };
  updateGoto() {
    this.pageNumbers = [];
    for (let i = 1; i <= Math.ceil(this.length / this.pageSize); i++) {
      this.pageNumbers.push(i);
    }
  }
  paginationChange(pageEvt: PageEvent) {
    this.pageSize = pageEvt.pageSize;
    this.length = pageEvt.length;
    this.updateGoto();
    this.goTo = pageEvt.pageIndex + 1;
  }
  goToChange(ent) {
    this.goTo = ent;
    this.paginator.pageIndex = this.goTo - 1;
    const event: PageEvent = {
      length: this.paginator.length,
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
    };
    this.paginator.page.next(event);
  }
  paginationPrevious(p) {
    if (this.goTo > 1) {
      this.goTo = this.goTo - 1;
      p.previous();
    }
  }
  paginationNext(p) {
    if (this.goTo < p.pages.length) {
      this.goTo = this.goTo + 1;
      p.next();
    }
  }
  onChange(i) {
    this.isOpen = i;
  }
  createExcelData() {
    let eachRowArray = [];
    const data = [...this.dataTransaction];
    [...data].forEach((values) => {
      [...this.displayedColumns].forEach((columnName) => {
        if (columnName == 'fund_name') {
          if (values?.product?.name) {
            eachRowArray.push(values?.product?.name);
          } else {
            eachRowArray.push('---');
          }
        } else if (columnName == 'fund_class') {
          if (values?.fund_class?.fund_class_name) {
            eachRowArray.push(values?.fund_class?.fund_class_name);
          } else {
            eachRowArray.push('---');
          }
        } else if (columnName == 'fund_series') {
          if (values?.fund_series?.fund_series_name) {
            eachRowArray.push(values.fund_series.fund_series_name);
          } else {
            eachRowArray.push('---');
          }
        } else if (columnName == 'units_of_share') {
          if (values?.number_of_shares) {
            eachRowArray.push(values.number_of_shares);
          } else {
            eachRowArray.push('---');
          }
        } else if (columnName == 'dealing_date') {
          if (values?.dealing_date) {
            eachRowArray.push(moment(values?.dealing_date).toDate());
          } else {
            eachRowArray.push('---');
          }
        } else {
          if (values[columnName] === null) eachRowArray.push('');
          else eachRowArray.push(values[columnName]);
        }
      });
      this.excelData.push(eachRowArray);
      eachRowArray = [];
    });
  }
}
