
export const b2cPolicies = {
  names: {
    signIn: 'B2C_1A_SIGNUP_SIGNIN',
    resetPassword: 'B2C_1A_PASSWORDRESET',
  },
  authorities: {
    signIn: {
      authority:
        'https://myportfolio-login.silverhorngroup.com/silverhornonlinenetwork.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN',
    },
    resetPassword: {
      authority:
        'https://myportfolio-login.silverhorngroup.com/silverhornonlinenetwork.onmicrosoft.com/B2C_1A_PASSWORDRESET',
    },
  },
  authorityDomain: 'myportfolio-login.silverhorngroup.com',
};
export const environment = {
  production: true,
  local: {
    activeDirectoryLocalAccountId: '',
    email: '',
  },
  core: {
    api: {
      endpoint: 'https://silverhorn-online-network-be-prod.azurewebsites.net/',
      key: 'lEvf1cvudlXf0g2n190W',
    },
    msal: {
      clientId: '8c5a1dfb-7a13-4f95-a32d-8a06b8b44bce',
      authority: b2cPolicies.authorities.signIn.authority,
      redirectUri: 'https://myportfolio.silverhorngroup.com/dashboard',
      knownAuthorities: [b2cPolicies.authorityDomain],
    },
    appInsights: {
      instrumentationKey: '7339ee2c-423b-4ab3-9352-bceab2b8bbf0'
    }
  },
};

