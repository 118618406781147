<div
  class="managed-account-container"
  *ngIf="
    init &&
    (totalData?.total_fund_asset_value_usd ||
      totalData?.total_managed_account_value)
  "
>
  <div class="main_card">
    <div class="information_account" [class.flex_mobi] ="breakpointCheck">
      <div class="account_number"*ngIf="entityInfo?.entity_full_number">
        <span class="name">Account Number</span>
        <span class="value">{{entityInfo.entity_full_number}}</span>
      </div>
      <div class="account_name" *ngIf="entityInfo?.name" [class.item_right] ="!breakpointCheck">
        <span class="name">Account Name</span>
        <span class="value">{{entityInfo.name}}</span>
      </div>
    </div>
    <div class="total_fund" *ngIf="totalData?.total_fund_asset_value_usd" [class.flex_mobi] ="breakpointCheck">
      <span class="name">Total Fund Asset Value (USD)</span>
      <span class="value">{{
        totalData.total_fund_asset_value_usd | number : '1.2-2'
      }}</span>
    </div>
    <div class="total_managed" *ngIf="totalData?.total_managed_account_value" [class.flex_mobi] ="breakpointCheck" >
      <span class="name">Total Managed Account Value (USD)</span>
      <span class="value">{{
        totalData.total_managed_account_value | number : '1.2-2'
      }}</span>
    </div>
    <div class="emails" [class.flex_mobi] ="breakpointCheck">
      <span class="name"> Emails </span>
      <div>
        <div detail *ngFor="let email of entityInfo.email" class="value" [class.text_right] ="!breakpointCheck">
          {{ email }}
        </div>
      </div>
      <div detail *ngIf="entityInfo.email.length == 0" class="name">---</div>
    </div>
  </div>
</div>
<div class="loading">
  <div class="lds-default" *ngIf="!init">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
