import { Inject, Injectable } from '@angular/core';
import {
  AccountInfo,
  AuthenticationResult,
  InteractionType,
  PopupRequest,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';
import {
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class MsalAuthService {
  private _userToken = new Subject<AuthenticationResult>();

  public userRoles$ = this._userToken.asObservable().pipe(
    map((token) => {
      // @ts-ignore
      return token?.idTokenClaims?.roles;
    }),
  );

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
  ) {}

  isLoggedIn(): boolean {
    return this.authService.instance.getAllAccounts().length > 0;
  }

  currentActiveUser(): any {
    if (!environment.production) {
      let data = {
        homeAccountId: '',
        environment: 'local',
        tenantId: '1',
        username: 'dev.com',
        name: 'dev', // + JSON.stringify(environment), // uncomment for testing
        localAccountId: environment.local.activeDirectoryLocalAccountId,
        idTokenClaims: {
          "signInNames.emailAddress": environment.local.email,
          }
        }
      
      return data;
    }
    this.checkAndSetActiveAccount();
    return this.authService.instance.getActiveAccount();
  }

  checkAndSetActiveAccount(): void {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }
  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService
          .loginPopup({
            ...this.msalGuardConfig.authRequest,
            ...userFlowRequest,
          } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService
          .loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
          ...userFlowRequest,
        } as RedirectRequest);
      } else {
        this.authService.loginRedirect(userFlowRequest);
      }
    }
  }
  logout() {
    const activeAccount =
      this.authService.instance.getActiveAccount() ||
      this.authService.instance.getAllAccounts()[0];

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        account: activeAccount,
      });
    } else {
      this.authService.logoutRedirect({
        account: activeAccount,
      });
    }
  }
  acquireTokenSilent(activeAccount?: AccountInfo): void {
    const silentRequest: SilentRequest = {
      scopes: [],
      forceRefresh: true,
    };

    if (!!activeAccount) {
      silentRequest.account = activeAccount;
    }

    this.authService.acquireTokenSilent(silentRequest).subscribe({
      next: (result) => {
        // console.log(result);
        this._userToken.next(result);
      },
    });
  }
  enableStorageEvent() {
    this.authService.instance.enableAccountStorageEvents();
  }
}
