import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { HoldingSummariesService } from '../holding-summaries.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-holding-summaries-filter',
  templateUrl: './holding-summaries-filter.component.html',
  styleUrls: ['./holding-summaries-filter.component.scss'],
})
export class HoldingSummariesFilterComponent implements OnInit {
  formGroup: UntypedFormGroup;
  nullForm;
  touched: boolean = false;
  constructor(
    private _fb: UntypedFormBuilder,
    private service: HoldingSummariesService,
    private datePipe: DatePipe,
  ) {}

  ngOnInit(): void {
    this.formGroup = this._createFormGroup();
    this.nullForm = this.formGroup.getRawValue();
  }
  private _createFormGroup(): UntypedFormGroup {
    return this._fb.group({
      valuation_date: [null],
    });
  }

  changeFilterValue(filterValue) {
    event.preventDefault();
    let formatFilterValue;
    if (filterValue.valuation_date) {
      formatFilterValue = {
        valuation_date: this.datePipe.transform(
          filterValue.valuation_date,
          'dd/MM/yyyy',
        ),
      };
      this.service.changeFilterValue(formatFilterValue);
    } else {
      this.service.changeFilterValue(filterValue);
    }
  }
  onReset() {
    this.touched = false;
    this.formGroup?.reset();
    this.service.changeFilterValue(this.nullForm);
  }
}
