import * as moment from 'moment';
import { FundTransaction } from 'src/app/funds/funds.const';

export const groupTransactionByDate = (data: FundTransaction[]) => {
  const today = moment().format('DD.MM.YYYY');
  const result = [
    ...data
      .reduce((r, o) => {
        const key =
          moment(o.dealing_date).format('DD.MM.YYYY') == today
            ? 'Today'
            : moment(o.dealing_date).format('DD.MM.YYYY');
        const item =
          r.get(key) ||
          Object.assign({}, 0, {
            date: key,
            transactions: [],
          });
        item.transactions.push(o);
        return r.set(key, item);
      }, new Map())
      .values(),
  ];
  return result;
};
