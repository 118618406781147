export interface ManagedAccountValue {
  entity: Entity[];
  summary: {
    total_commitment_lcy: number;
    total_commitment_usd: number;
    total_fund_asset_value_lcy: number;
    total_fund_asset_value_usd: number;
    total_managed_account_value: number;
    total_number_of_shares: number;
    total_redemption_lcy: number;
    total_redemption_usd: number;
    total_remaining_commitment_lcy: number;
    total_remaining_commitment_usd: number;
    total_subscription_lcy: number;
    total_subscription_usd: number;
  }[];
}
export interface TransactionsByQuarter {
  distribution: number;
  drawdown: number;
  quarter: number;
  redemption: number;
  subscription: number;
  transfer_in: number;
  transfer_out: number;
  year: number;
}
export interface ManagedAccountHistory {
  bond: number;
  client: string;
  client_account: ClientAccount;
  liquidity: number;
  net_asset_value_usd: number;
  statement_date: Date;
  total_asset_value_usd: number;
  createdAt: Date;
  updatedAt: Date;
}
export interface ClientAccount {
  _id: string;
  account_status: string;
  account_type: string;
  client: string;
  client_account_close_date: Date;
  client_account_num: string[];
  client_account_open_date: Date;
  entity_agreement: string;
  fund_admin: string;
  managed_end_date: Date;
  managed_start_date: Date;
  other_port_acc_no: string[];
  other_port_bank_name: string;
  port_acc_open_date: string;
  createdAt: Date;
  updatedAt: Date;
}
export interface Fund {
  _id: string;
  type: string;
  ref: string;
  name: string;
  internal: boolean;
  series_accounting: boolean;
  currency: string;
  conflicts_of_interest:string;
  fund_type: string;
  investment_objective: string;
  legal_structure: string;
  domicile: string;
  custodian: string;
  paying_agent: string;
  fund_admin: string;
  max_subsequence_subscription: string;
  min_settlement: string;
  valuation_frequency: string;
  valuation_date_description: string;
  subscription_frequency: string;
  redemption_notification_period: string;
  fund_structure: string;
  __v: number;
  createdAt: Date;
  updatedAt: Date;
  fund_prod_admin: string;
  fund_prod_paying_agent: string;
  fund_prod_custodian: string;
  asset_class: string;
  investment_structure: string;
  investment_strategy: string;
  investment_opportunity: string;
  dpi: number;
  afim?: any;
  auditor: string;
  inception_date?: any;
  irr?: any;
  is_deleted: boolean;
  moic?: any;
  product_owner: string;
  remarks?: any;
  swiss_representative_agent: string;
  tvpi?: any;
  fund_status: string;
  fundraising_status: boolean;
  updatedBy: string;
  portfolio_manager: string;
  legal_counsel: string;
  fund_setup: string;
  cessation_date?: any;
  quarterly_reports: any[];
}

export interface FundClass {
  _id: string;
  fund: string;
  fund_class_name: string;
  is_sidepocket: boolean;
  isin: string;
  currency: string;
  min_subscription: number;
  is_reporting_share_class: boolean;
  short_name: string;
  ref: string;
  __v: number;
  createdAt: Date;
  updatedAt: Date;
  redemption_deadline: string;
  subscription_frequency: string;
  valuation_frequency: string;
  is_inactive: boolean;
}

export interface RegisteredAddress {
  line1: string;
  line2: string;
  line3: string;
  line4?: any;
}

export interface CorrespondenceAddress {
  line1: string;
  line2: string;
  line3: string;
  line4?: any;
}

export interface ResidentialAddress {
  line1?: any;
  line2?: any;
  line3?: any;
  line4?: any;
}

export interface Entity {
  _id: string;
  phone_number: any[];
  email: string[];
  entity_no: number;
  name: string;
  client_activity_status: string;
  client_advisor_si: string;
  client_type: string;
  investor_type: string;
  risk_profile: number;
  risk_rating: number;
  derivative_knowledge: boolean;
  unsophisticated_client: string;
  mltf_risk: string;
  partner_capital: boolean;
  ref: string;
  location: string;
  client_approval_status: string;
  entity_type: string;
  pi_status: string[];
  date_of_incorporation: Date;
  company_number: string;
  business_location: string;
  business_nature: string;
  regulatory_registration_information: string;
  letter_head: string;
  registered_address: RegisteredAddress;
  registered_country: string;
  correspondence_address: CorrespondenceAddress;
  correspondence_country: string;
  fatca: string;
  face_to_face_meeting: boolean;
  direct_marketing: boolean;
  e_signature: boolean;
  __v: number;
  updatedAt: Date;
  createdAt: Date;
  aml_reliance_on_third_party?: any;
  birth_place?: any;
  date_of_birth?: any;
  employed_by_sfc_licensed_firm?: any;
  estimated_net_worth?: any;
  expected_aum?: any;
  gender?: any;
  national_id?: any;
  nationality?: any;
  occupation?: any;
  passport_expiry_date?: any;
  passport_number?: any;
  remarks?: any;
  residence_country?: any;
  residential_address: ResidentialAddress;
  approval_date: Date;
  alias?: any;
  first_name: string;
  last_name?: any;
  middle_name?: any;
  client_gst_status: string;
  mltf_flags: string[];
  entity_full_number: string;
  latest_cdd_review: Date;
  latest_crr_review?: any;
  updatedBy: string;
  agreement_type?: any;
  domicile?: any;
  place_of_incorporation?: any;
}
export interface PositionFund {
  _id: string;
  fund: Fund;
  fund_class: FundClass;
  fund_series?: FundSeries;
  entity: Entity;
  location: string;
  fund_nav: number;
  nav_date:Date;
  total_commitment_lcy: number;
  total_commitment_usd: number;
  total_redemption_lcy: number;
  total_redemption_usd: number;
  total_fund_asset_value_usd: number;
  total_fund_asset_value_lcy: number;
  total_drawdown_lcy: number;
  total_drawdown_usd: number;
  total_remaining_commitment_lcy: number;
  total_remaining_commitment_usd: number;
  total_subscription_lcy: number;
  total_subscription_usd: number;
  currency: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  total_managed_account_value: number;
  percentage_ownership: number;
  total_number_of_shares: number;
}
export interface FundSeries {
  _id: string;
  fund: string;
  fund_class: string;
  fund_series_name: string;
  series_short_name: string;
  ref: string;
  __v: number;
  createdAt: Date;
  updatedAt: Date;
  is_inactive: boolean;
}
export const MANAGED_ACCOUNT_VALUE_URL = 'positions/summary';
export const TRANSACTION_BY_QUARTER_URL = 'transactions/by-quarter';
export const MANAGED_ACCOUNT_HISTORY_URL = 'managed-accounts/history';
export const CLIENT_FUND_POSITION_LIST_URL = 'positions/';
export const columnsPositionDetailList = [
  'fund.name',
  'fund_class.short_name',
  'fund_series.fund_series_name',
  'currency',
  'total_fund_asset_value_lcy',
  'total_fund_asset_value_usd',
  'total_number_of_shares',
  'total_commitment_lcy',
  'total_subscription_lcy',
  'total_redemption_lcy',
  'total_remaining_commitment_lcy',
  'fund_nav',
];
export const columnsPositionDetailNameInTable = [
  'Fund Name',
  'Fund Class',
  'Fund Series',
  'CCY',
  'Asset Value (LCY)',
  'Asset Value (USD)',
  'Number of Shares',
  'Commitment',
  'Subscription/Contribution',
  'Redemption/Distribution',
  'Remaining Commitment',
  'NAV per share',
];
