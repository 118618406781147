import { Injectable } from '@angular/core';
import { ApiHttpService } from '@core/api/api-http.service';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { TransactionsByQuarter } from '../dashboard/dashboard.const';
import {
  FundPosition,
  FUNDS_LIST,
  FUND_QUARTER_CHART,
  FUND_TRANSACTION,
} from './funds.const';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FundsService {
  private FundTransactionValues =  new BehaviorSubject<any>({});
  public FundTransactionValues$ = this.FundTransactionValues.asObservable();
  constructor(private apiService: ApiHttpService,private _http: HttpClient) {}
  public getFundList(): Observable<FundPosition[]> {
    return this.apiService.get(FUNDS_LIST);
  }
  public getFundQuarterChart(id: string): Observable<TransactionsByQuarter[]> {
    return this.apiService.get(FUND_QUARTER_CHART(id));
  }
  changeFilterValue(value: any,id): void {
    this.FundTransactionValues.next({value,id});
  }
  public getFundTransaction(id: string): Observable<any[]> {
    return this.apiService.get(FUND_TRANSACTION(id));
  }
  public downloadDocument(url){
    return this._http
      .get(
        url,
        {
          responseType: 'blob',
          observe: 'response',
          headers: new HttpHeaders({
            'x-email':environment.local.email,
            'x-active-directory-local-account-id':environment.local.activeDirectoryLocalAccountId,
            'x-auth-token':environment.core.api.key,
          }),
        },
      )
      .pipe(
        map((response) => (
          {
          body: response.body,
          contentDisposition: response.headers.get('content-disposition') || '',
          contentType:
          response.headers.get('content-type'),
        })),
      );
  }
}
