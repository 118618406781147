import { Component, Input, OnInit } from '@angular/core';
import { HoldingSummariesService } from '../holding-summaries.service';
import * as moment from 'moment';
import { FilterPipe } from 'src/app/pipes/filter.pipe';
import { saveAs } from 'file-saver';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-holding-summaries-table',
  templateUrl: './holding-summaries-table.component.html',
  styleUrls: ['./holding-summaries-table.component.scss'],
})
export class HoldingSummariesTableComponent implements OnInit {
  dataSouce = [];
  filterData;
  dataHoldingSummmary;
  @Input() isToolTip: boolean;
  constructor(
    private service: HoldingSummariesService,
    private filterPipe: FilterPipe,
  ) {}

  ngOnInit(): void {
    this.service.filterValue$.subscribe((data) => {
      let dateFormat;
      if (data?.valuation_date) {
        let date = moment(data?.valuation_date, 'DD/MM/YYYY');
        dateFormat = date.format('YYYY-MM-DD');
      }
      this.filterData = {
        valuation_date: data?.valuation_date ? dateFormat : null,
      };
      let filterData = this.filterPipe.transform(
        this.dataHoldingSummmary,
        this.filterData,
      );
      this.dataSouce = [];
      this.dataSouce = filterData;
    });
    this.service.getHoldingSummaryList().subscribe((data) => {
      this.dataHoldingSummmary = this.calculatorTotalAssetValue(
        data,
        'asset_value',
      );
      this.dataSouce = this.dataHoldingSummmary;
    });
  }
  calculatorTotalAssetValue(data, type) {
    const result = [
      ...data
        .reduce((r, o) => {
          const key = o[type];
          const item =
            r.get(key) ||
            Object.assign(
              {},
              {
                valuation_date: o.valuation_date,
              },
              {
                total_asset_value: 0,
              },
              {
                urlView: o.urlView,
              },
              {
                urlDownload: o.urlDownload,
              },
              {
                namePDF: o?.entity?.entity_full_number,
              },
            );
          if (o?.asset_value) {
            o.asset_value.map((value) => {
              item.total_asset_value += value.asset_value_usd;
            });
          }
          return r.set(key, item);
        }, new Map())
        .values(),
    ];
    let list = result.sort((a, b) => {
      return moment(a) > moment(b) ? 1 : -1;
    });
    list = list.filter((data) => {
      return data.total_asset_value !== 0;
    });
    return list;
  }
  viewHoldingSummray(row) {
    if (row.urlView) {
      window.open(
        `${row.urlView}`,
        '_blank',
      );
    }
  }
  downloadHoldingSummary(row) {
    this.service.downloadPDF(row.urlDownload).subscribe((res) => {
      const dateFormat = new Date();
      const filename = row.namePDF + dateFormat;
      saveAs(new Blob([res.body], { type: 'application/pdf' }), filename);
    });
  }
}
