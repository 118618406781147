import { HomeSideNav } from './home-sidenav.interface';

export const SIDENAV_CONTENTS: HomeSideNav[] = [
  {
    name: 'Dashboard',
    svgIcon: 'dashboard',
    navLink: '/dashboard',
  },

  {
    name: 'Funds',
    svgIcon: 'funds',
    navLink: '/funds',
  },
  {
    name: 'Transactions',
    svgIcon: 'transactions',
    navLink: '/transactions',
  },
  {
    name: 'Holding Summaries',
    svgIcon: 'holding-summaries',
    navLink: '/holding-summaries',
  },
];
