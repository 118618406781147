import { Component, OnInit } from '@angular/core';
import { PositionFund } from '../dashboard.const';
import { DashboardService } from '../dashboard.service';
@Component({
  selector: 'app-client-fund-position',
  templateUrl: './client-fund-position.component.html',
  styleUrls: ['./client-fund-position.component.scss'],
})
export class ClientFundPositionComponent implements OnInit {
  data: PositionFund[] = [];
  init = false;
  totalSubscription: any;
  totalCommitment: any;
  multiAssetData: any;
  privateDebtData: any;
  privateEquityData: any;
  dataBarChart: any;
  constructor(private service: DashboardService) {}

  ngOnInit(): void {
    this.service.getClientFundPositionList().subscribe((data) => {
      this.dataBarChart = this.dataChart(data, 'entity');
      this.data = data;
      this.init = true;
    });
  }
  dataChart(data, type) {
    const result = [
      ...data
        .reduce((r, o) => {
          const key = o[type];
          const item =
            r.get(key) ||
            Object.assign(
              {},
              { total_redemption: 0 },
              {
                total_fund_asset_value: 0,
              },
              {
                total_subscription: 0,
              },
            );
          item.total_redemption += o.total_redemption_usd;
          item.total_fund_asset_value += o.total_fund_asset_value_usd;
          item.total_subscription += o.total_subscription_usd;
          return r.set(key, item);
        }, new Map())
        .values(),
    ];
    return result;
  }
}
