<!-- 1366px above template -->
<div class="fund-transaction-container" *ngIf="!breakpointCheck">
  <div class="fund-transaction-table">
    <table
      mat-table
      matSort
      class="transaction-table"
      [dataSource]="dataSource"
      fxFill
      multiTemplateDataRows
      (matSortChange)="sortData(dataSource)"
      >
    >
      <ng-container matColumnDef="class" *ngIf="fundStructure !== 'GP/LP'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="label">
          Class
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row?.fund_class?.fund_class_name }} 
        </td>
      </ng-container>
      <ng-container matColumnDef="series" *ngIf="fundStructure !== 'GP/LP'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="label">
          Series
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row?.fund_series?.fund_series_name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="currency">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="label">
          Currency
        </th>
        <td mat-cell *matCellDef="let row">{{ row?.currency }}</td>
      </ng-container>
      <ng-container matColumnDef="transaction_type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="label">
          Transaction Type
        </th>
        <td mat-cell *matCellDef="let row">{{ row?.transaction_type }}</td>
      </ng-container>
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="label" [ngStyle]="{'padding-right.px': fundStructure !== 'GP/LP' ? 5 : 50}">
          Amount
        </th>
        <td mat-cell *matCellDef="let row" class="number" [ngStyle]="{'padding-right.px': fundStructure !== 'GP/LP' ? 5 : 50}">{{ row?.amount | number : '1.2-2' }}</td>
      </ng-container>
      <ng-container matColumnDef="number_of_shares" *ngIf="fundStructure !== 'GP/LP'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="label">
          Number of Shares
        </th>
        <td mat-cell *matCellDef="let row" class="number">{{ row?.number_of_shares | number : '1.2-2' }}</td>
      </ng-container>
      <ng-container matColumnDef="dealing_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="label">
          Dealing Date
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row?.dealing_date | date : 'dd/MM/yyyy' }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr mat-row class="detail-row"></tr>
    </table>
  </div>
</div>
<!-- Mobile and Tablet Template -->
<div class="fund-transaction-mobile-container" *ngIf="breakpointCheck">
  <div class="transaction-mobile" *ngFor="let transactionByDate of data">
    <div class="date">
      {{ transactionByDate.date }}
    </div>
    <div
      class="date-transaction"
      *ngFor="let transaction of transactionByDate.transactions"
    >
      <div class="transaction-info">
        <span class="transaction-name">{{
          transaction?.fund_class?.fund_class_name
        }}</span>
        <span class="transaction-type">{{
          transaction?.transaction_type
        }}</span>
      </div>
      <div class="transaction-amount">
        <span class="transaction-amount">{{
          transaction?.amount | number : '1.2-2'
        }}</span>
        <span
          class="transaction-status"
          [ngClass]="
            transaction?.status
              ? 'transaction-status-' + transaction?.status
              : 'transaction-status-null'
          "
          >{{ transaction?.status }}</span
        >
      </div>
    </div>
  </div>
</div>
