<mat-toolbar
  class="toolbar-transaction"
  [class.toolbar-transaction-mobi]="breakpointCheck"
>
  <div
    class="transaction-toolbar-container"
    [class.transaction-toolbar-container-mobi]="breakpointCheck"
  >
    <form [formGroup]="formGroup">
      <div
        class="transaction-table-filter"
        [class.transaction-table-filter-mobi]="breakpointCheck"
      >
        <div fxLayout="column" class="input">
          <mat-form-field appearance="fill">
            <mat-select
              placeholder="Fund Name"
              floatlLabel="never"
              formControlName="fund_name"
              (selectionChange)="changeFilterValue(formGroup.value)"
            >
              <mat-option [value]="null">All</mat-option>
              <mat-option
                *ngFor="let itemsFundName of fundNameOptions"
                [value]="itemsFundName.desc"
                >{{ itemsFundName.desc }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="column" class="input">
          <mat-form-field appearance="fill">
            <mat-select
              placeholder="Fund Class"
              floatlLabel="never"
              formControlName="fund_class"
              (selectionChange)="changeFilterValue(formGroup.value)"
              [disabled]="isSelectFundClass"
            >
              <mat-option [value]="null">All</mat-option>
              <mat-option
                *ngFor="let itemsFundClass of fundClassOptions"
                [value]="itemsFundClass.desc"
                >{{ itemsFundClass.desc }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="column" class="input">
          <mat-form-field appearance="fill">
            <mat-select
              placeholder="Fund Series"
              floatlLabel="never"
              formControlName="fund_series"
              (selectionChange)="changeFilterValue(formGroup.value)"
              [disabled]="isSelectFundSeries"
            >
              <mat-option [value]="null">All</mat-option>
              <mat-option
                *ngFor="let itemsFundSeries of fundSeriesOptions"
                [value]="itemsFundSeries.desc"
                >{{ itemsFundSeries.desc }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="column" class="input">
          <mat-form-field appearance="fill">
            <mat-select
              placeholder="Transaction Type"
              floatlLabel="never"
              formControlName="transaction_type"
              (selectionChange)="changeFilterValue(formGroup.value)"
            >
              <mat-option [value]="null">All</mat-option>
              <mat-option
                *ngFor="let itemsTransactionType of transactionTypeOptions"
                [value]="itemsTransactionType.desc"
                >{{ itemsTransactionType.desc }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </form>
    <div class="download" [class.download-mobi]="breakpointCheck" (click)="downloadExcel($event)">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M26.25 18.75L25.375 17.875L20.625 22.625V11.25H19.375V22.625L14.625 17.875L13.75 18.75L20 25L26.25 18.75ZM26.25 25V27.5H13.75V25H12.5V27.5C12.5 28.25 13 28.75 13.75 28.75H26.25C27 28.75 27.5 28.25 27.5 27.5V25H26.25Z"
          fill="white"
        />
      </svg>
    </div></div
></mat-toolbar>
