import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FundTransaction } from '../../funds.const';
import { FundsService } from '../../funds.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { delay } from 'rxjs';
import { groupTransactionByDate } from '@shared/utils/groupTransaction';
import { FilterPipe } from 'src/app/pipes/filter.pipe';
@UntilDestroy()
@Component({
  selector: 'app-fund-transaction',
  templateUrl: './fund-transaction.component.html',
  styleUrls: ['./fund-transaction.component.scss'],
})
export class FundTransactionComponent implements OnInit {
  @Input() fundID: string;
  @Input() fundStructure: string;
  @Input() dataTable;
  data: {
    data: string;
    transactions: FundTransaction;
  }[] = [];
  filterValue;
  dataFundTransaction;
  dataSource: MatTableDataSource<FundTransaction>;
  displayedColumns = [
    'class',
    'series',
    'currency',
    'transaction_type',
    'amount',
    'number_of_shares',
    'dealing_date',
  ];
  fundTransactionFilter = {};
  breakpointCheck = false;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private service: FundsService,
    private observer: BreakpointObserver,
    private filterPipe: FilterPipe,
  ) {}
  ngAfterViewInit() {
    // Breakpoint at 1366px
    this.observer
      .observe(['(max-width: 1366px)'])
      .pipe(delay(1), untilDestroyed(this))
      .subscribe((res) => {
        if (res.matches) {
          this.breakpointCheck = true;
        } else {
          this.breakpointCheck = false;
        }
      });
  }
  ngOnInit(): void {
    this.service.FundTransactionValues$.subscribe((data) => {
      if (!!data && !!this.dataSource && this.fundID == data.id) {
        const value = data.value;
        if (value.fund_series !== null) {
          this.fundTransactionFilter = {
            fund_class: value.fund_class
              ? { fund_class_name: value.fund_class }
              : null,
            fund_series: value.fund_series
              ? { fund_series_name: value.fund_series }
              : null,
          };
        } else {
          this.fundTransactionFilter = {
            fund_class: value.fund_class
              ? { fund_class_name: value.fund_class }
              : null,
          };
        }
        let filterData = this.filterPipe.transform(
          this.dataFundTransaction,
          this.fundTransactionFilter,
          'Absolute comparison'
        );
        this.data = groupTransactionByDate(filterData);
        this.filterValue = data.value;
        this.dataSource.filter = JSON.stringify(this.filterValue);
      }
    });
      this.data = groupTransactionByDate(this.dataTable);
      this.dataFundTransaction = this.dataTable;
      this.dataSource = new MatTableDataSource(this.dataTable);
      this.dataSource.filterPredicate = this.filterPredicate;
    if (this.fundStructure === 'GP/LP') {
      this.displayedColumns = [
        'currency',
        'transaction_type',
        'amount',
        'dealing_date',
      ];
    }
  }
  filterPredicate = (items, filter: string) => {
    if (this.filterValue) {
      let isMatched =
        (!!this.filterValue?.fund_class
          ? items.fund_class?.fund_class_name
              ?.toLowerCase() === this.filterValue?.fund_class.toLowerCase()
          : true) &&
        (!!this.filterValue?.fund_series
          ? items.fund_series?.fund_series_name
              ?.toLowerCase() === this.filterValue?.fund_series.toLowerCase()
          : true);

      return isMatched;
    }
    return true;
  };
  sortData(data){
    data.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'class':
            return item.fund_class?.short_name;
          case 'series':
            return item.fund_series?.fund_series_name;
          case 'currency':
            return item.currency;
          case 'transaction_type':
            return item.transaction_type;
          case 'amount':
            return item.amount;
          case 'number_of_shares':
            return item.number_of_shares;
          case 'dealing_date':
            return item.dealing_date;
          default:
            return item[property];
        }
      };
    data.sort = this.sort;
  }
}
