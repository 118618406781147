import { Component, OnInit } from '@angular/core';
import { Entity } from '../dashboard.const';
import { DashboardService } from '../dashboard.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { delay } from 'rxjs';
@UntilDestroy()
@Component({
  selector: 'app-managed-account-value',
  templateUrl: './managed-account-value.component.html',
  styleUrls: ['./managed-account-value.component.scss'],
})
export class ManagedAccountValueComponent implements OnInit {
  totalData;
  entityInfo: Entity;
  init = false;
  breakpointCheck = false;
  constructor(
    private service: DashboardService,
    private observer: BreakpointObserver,
  ) {}
  ngAfterViewInit() {
    // Breakpoint at 1366px
    this.observer
      .observe(['(max-width: 1366px)'])
      .pipe(delay(1), untilDestroyed(this))
      .subscribe((res) => {
        if (res.matches) {
          this.breakpointCheck = true;
        } else {
          this.breakpointCheck = false;
        }
      });
  }
  ngOnInit(): void {
    this.service.getManagedAccountValue().subscribe((data) => {
      this.totalData = data.summary[0];
      this.entityInfo = data.entity[0];
      this.init = true;
    });
  }
}
