import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeHeaderComponent } from './home-header/home-header.component';
import { HomeSidenavComponent } from './home-sidenav/home-sidenav.component';
import { HomeComponent } from './home.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [HomeHeaderComponent, HomeSidenavComponent, HomeComponent],
  imports: [CommonModule, SharedModule],
  exports: [HomeComponent],
})
export class HomeModule {}
