import {
  Entity,
  Fund,
  FundClass,
  FundSeries,
} from '../dashboard/dashboard.const';
export interface Position {
  _id: string;
  fund: string;
  fund_class: FundClass;
  fund_series?: FundSeries;
  entity: string;
  location: string;
  fund_nav: number;
  total_commitment_lcy: number;
  total_commitment_usd: number;
  total_subscription_lcy: number;
  total_subscription_usd: number;
  total_redemption_lcy: number;
  total_redemption_usd: number;
  total_fund_asset_value_usd: number;
  total_fund_asset_value_lcy: number;
  total_drawdown_lcy: number;
  total_drawdown_usd: number;
  total_remaining_commitment_lcy: number;
  total_remaining_commitment_usd: number;
  currency: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  total_managed_account_value: number;
  percentage_ownership: number;
  total_number_of_shares: number;
  net_asset_value: NetAssetValue[];
}
export interface NetAssetValue {
  _id: string;
  fund_class: string;
  nav_date: Date;
  nav_lcy: number;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  fund_series?: FundSeries;
  nav_usd?: number;
  nav_lcy_indicative?: any;
  number_of_shares?: number;
  aum_lcy?: number;
  fx_rate?: number;
  nav_type: string;
}
export interface FundPosition {
  fund: Fund;
  entity: Entity;
  documents:[]
  positions: Position[];
  total_managed_account_value: number;
  total_fund_asset_value_usd: number;
  total_remaining_commitment_usd: number;
  total_subscription_usd: number;
}
export interface FundTransaction {
  _id: string;
  is_restructured: boolean;
  is_cancelled: boolean;
  product: Fund;
  fund_class: FundClass;
  fund_series?: FundSeries;
  entity: Entity;
  currency: string;
  transaction_type: string;
  number_of_shares: number;
  offering_price: number;
  amount: number;
  dealing_date: Date;
  order_date: Date;
  status: string;
  client_bank?: any;
  product_type: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  is_deleted: boolean;
}
export const FUNDS_LIST = 'positions/group-by-fund';
export const FUND_QUARTER_CHART = (id: string) =>
  `transactions/by-fund-and-quarter?productId=${id}`;
export const FUND_TRANSACTION = (id: string) => `transactions?fund=${id}`;
