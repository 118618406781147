import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from '@core/core.module';
import { HomeModule } from './home/home.module';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { DashboardModule } from './dashboard/dashboard.module';
import { FundsModule } from './funds/funds.module';
import { TransactionsModule } from './transactions/transactions.module';
import { FilterModule } from './pipes/filter/filter.module';
import { HoldingSummariesModule } from './holding-summaries/holding-summaries.module';
import { ApplicationInsightsErrorHandler } from './errorhanding/error-handler.service';
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    CoreModule,
    HomeModule,
    DashboardModule,
    FundsModule,
    TransactionsModule,
    FilterModule,
    HoldingSummariesModule
  ],
  providers: [{ provide : ErrorHandler, useClass : ApplicationInsightsErrorHandler},],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
